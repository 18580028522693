import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { Paper, Grid, Select, MenuItem } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DataGrid } from '@material-ui/data-grid';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import * as Site from "../../../store/ducks/site.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import { format } from "date-fns";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../../../_metronic/_assets/css/bootstrap/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';


export default function SiteItemTemplates() {
    
  const dispatch = useDispatch()
  const siteitemtemplates = useSelector(state => state.reference.siteitemtemplates)
  const filters = useSelector(state => state.site.Filters)

  const searchSites = (siteitemtemplate) => {
    if( true
        && (siteitemtemplate.name.toLowerCase().search(filters.siteItemTemplatesTerm.toLowerCase()) > -1 || filters.siteItemTemplatesTerm.length === 0)
        && (filters.sitesItemTemplateFlags === 'All' || (siteitemtemplate.status === 'Active' && filters.sitesItemTemplateFlags === 'Active') || (siteitemtemplate.status === 'InActive' && filters.sitesItemTemplateFlags === 'InActive') || (siteitemtemplate.status === 'Deleted' && filters.sitesItemTemplateFlags === 'Deleted') ))
    {
      return siteitemtemplate
    }  
  }
    
  const filteredSiteItemTemplates = siteitemtemplates.filter(siteitemtemplate => searchSites(siteitemtemplate))
  useEffect( () => { dispatch(Reference.actions.fetchSiteItemTemplates()) }, [dispatch] )
  var [show, setShow] = useState(false)
  
  function handleClose() {
    setShow(false);
  }  
  
  // general function to capture Enter key press (to align it with tab/onBlur) - this will prevent the form being submitted and run relevant update/save methods instead
  function onKeyDown(event) {
    if ((event.charCode || event.keyCode) === 13) {
      console.log(event);
      // // new checklist item
      // if(event.target.name === 'newLabel')
      // {
      //   addLabel(event.target.value)
      // }
      event.preventDefault();
    }
  }

  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
  }

  function saveElement(field,event) {
    if(event.key === 'Enter' || event.key === undefined)
    {
      // console.log(event);
      // updateElement(event.target.name, 'label', event.target.value)
    }
  }

  function addElement(abbr,label) {
    // Setup new element to add 
    var newItem = { id: makeid(6), abbreviation: abbr, label: label}
    var tempItems = currentItems ? [ ...currentItems ] : [];
    tempItems.push(newItem);
    setcurrentItems(tempItems);
  }

  function updateElement(id, field, value) {
    // merge in new label into item in items array
    var tempItems = [ ...currentItems ]
    tempItems.forEach(function(obj) {
    if (obj.id === parseInt(id)) 
    {
      if (field === 'label')
      {
        obj.label = value;  
      }
      else
      {
        obj.abbreviation = value;  
      }
    }
    setcurrentItems(tempItems);
  });
  }

  function deleteLabel(rowindextoDelete) {
    var tempItems = currentItems.filter((row, index) => index !== rowindextoDelete);
    setcurrentItems(tempItems);
  }

  function AddItem () {
    // Define variables
    const [newAbbreviation, setNewAbbreviation] = useState('');
    const [newLabel, setNewLabel] = useState('');
    // Handle changes 
    function addNew() {
      addElement(newAbbreviation,newLabel)
      // Reset variables 
      setNewLabel('')
      setNewAbbreviation('')
    }
    return(
      <>
        <Form.Control style={{ height: 40, maxWidth: 60 }} type="text" id="itemAbbreviation" placeholder="Abbr." name="newAbbreviation" value={newAbbreviation} onChange={(event) => setNewAbbreviation(event.target.value)} autoComplete='new-password'/> 
        <Form.Control style={{ height: 40 }} type="text" id="itemLabel" placeholder="Label" name="newLabel" value={newLabel} onChange={(event) => setNewLabel(event.target.value)} autoComplete='new-password'/>
        <Button variant="primary" onClick={addNew} style={{ height: 38, marginTop: 1 }}>
          Add
        </Button>
      </>
    )
  }

  function handleOnDragEnd(result) {
    // create a new re-ordered array from the draggable context and set it back to state
    if(result.destination) {
      const itemsCopy = Array.from(currentItems);
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
      itemsCopy.splice(result.destination.index, 0, reorderedItem);
      setcurrentItems(itemsCopy);
    }
  }

  const deleteSiteItemTemplate = (thisSiteItemTemplate) => {
    // Ok change status of a site item template to deleted 
    dispatch(Reference.actions.deleteSiteItemTemplate({ id: thisSiteItemTemplate.id, type: 'delete'}))
  }

  const undeleteSiteItemTemplate = (thisSiteItemTemplate) => {
    // Ok change status of a site item template to from deleted to active
    dispatch(Reference.actions.deleteSiteItemTemplate({ id: thisSiteItemTemplate.id, type: 'undelete'}))
  }

  const copySiteItemTemplate = (thisSiteItemTemplate) => {
    // Ok copy current site item template - needs to be done in the API due to the images and siteplans in the blob
    dispatch(Reference.actions.copySiteItemTemplate({ id: thisSiteItemTemplate.id}))
  }

  function putSiteItemTemplate() {
    // Create payload to send to API 
    var payload = {
      id: currentSiteItemTemplateID,
      name: currentName,
      status: currentStatus,
      siteItems: currentItems
    }
    //  
    //
    process.env.REACT_APP_DEBUG && console.log(payload);
    process.env.REACT_APP_DEBUG && console.log ('Saving data');
    // Save update 
    dispatch(Reference.actions.putSiteItemTemplate(payload));
    setShow(false);
  }

  const loadNewSiteItemTemplate = (thisSiteItemTemplate) => {
    setcurrentSiteItemTemplateID(0);
    setcurrentName();
    setcurrentStatus('Active');
    setcurrentItems([]);
    // Load Form
    setShow(true);
  }

    const loadSiteItemTemplate = (thisSiteItemTemplate) => {
    setcurrentSiteItemTemplateID(thisSiteItemTemplate.id);
    setcurrentName(thisSiteItemTemplate.name);
    setcurrentStatus(thisSiteItemTemplate.status);
    setcurrentItems(thisSiteItemTemplate.siteItems);
    // Load Form
    setShow(true);
  }

  var [currentSiteItemTemplateID, setcurrentSiteItemTemplateID] = useState();
  var [currentName, setcurrentName] = useState();
  var [currentStatus, setcurrentStatus] = useState();
  var [currentItems,setcurrentItems] = useState([]);

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'name', headerName: 'Name', width: 185,
    renderCell: (params) => (
      <strong>
        <div onClick={() => loadSiteItemTemplate(siteitemtemplates.find(siteitemtemplate => siteitemtemplate.id === params.row.id))} className="cell-link">
          {params.value} 
        </div>
      </strong>
    ),  },
    { field: 'updatedOn', headerName: 'Updated On', width: 160,  renderCell: (params) => (format(new Date(params.row.updatedOn), "MM-dd-yyyy HH:mm")) },
    { field: 'status', headerName: 'Status', width: 110 },
    { field: 'none', headerName: 'Actions', width: 200,
    renderCell: (params) => (
      <>
        <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#eee", marginRight: 10 }} onClick={() => copySiteItemTemplate(siteitemtemplates.find(siteitemtemplate => siteitemtemplate.id === params.row.id))}>
           Copy 
        </Button>
        {params.row.status === 'Deleted' ?
          <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#faefcf", marginRight: 10 }} onClick={() => undeleteSiteItemTemplate(siteitemtemplates.find(siteitemtemplate => siteitemtemplate.id === params.row.id))}>
            UnDelete 
          </Button>
        :
          <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#faefcf", marginRight: 10 }} onClick={() => deleteSiteItemTemplate(siteitemtemplates.find(siteitemtemplate => siteitemtemplate.id === params.row.id))}>
            Delete 
          </Button>
        }
      </>
    ),  },
  ];

  return (
    <>
      <h4>
        Site Item Templates
      </h4>
      <Paper> 
        <Grid container style={{ padding: 10 }}>
          <Grid item md={3}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
                <Form.Control 
                  type="text"
                  value={filters.siteItemTemplatesTerm}
                  onChange={(event) => dispatch(Site.actions.setFilters({ filterName: 'siteItemTemplatesTerm', filterValue: event.target.value }))}
                  autoComplete='OFF'
                  style={{ maxWidth: 170, height: 35, backgroundColor: filters.sitesTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                />
              <InputGroup.Append onClick={() => dispatch(Site.actions.setFilters({ filterName: 'sitesTerm', filterValue: '' }))} style={{ cursor: 'pointer'}}>
                <InputGroup.Text>
                  <ClearIcon fontSize="small" style={{ color: filters.sitesTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Grid>
          <Grid item md={6}>
            <Grid container>
              <Grid item>
                <Select
                  variant="outlined"
                  margin="dense"
                  value={filters.sitesItemTemplateFlags}
                  onChange={(event) => dispatch(Site.actions.setFilters({ filterName: 'sitesItemTemplateFlags', filterValue: event.target.value }))}
                  style={{ minWidth: 140, marginLeft: 15, backgroundColor: "#ffffff" }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="InActive">InActive</MenuItem>
                  <MenuItem value="Deleted">Deleted</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ textAlign: 'right' }} md={3}>
            <Button variant="primary" size="sm" onClick={() => loadNewSiteItemTemplate()}>
              Add Site Item Template
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ height: 700 }}>
        <DataGrid rows={filteredSiteItemTemplates} columns={columns} pageSize={10} checkboxSelection={false} sortModel={[{ field: 'name', sort: 'desc' }]} />
      </Paper>

      <Modal show={show} onHide={handleClose} dialogClassName="edit-sm-dialog-modal">
        <Modal.Header closeButton className="edit-sm-modal">
          <Modal.Title>Edit Site Item Template - {currentName} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-sm-modal" onKeyDown={onKeyDown}>
          <Form onSubmit={putSiteItemTemplate}>
            <Row>
                <Col md="9">
                <Form.Group >
                    <Form.Label>Template Name</Form.Label>
                    <Form.Control type="text" name="siteName" defaultValue={currentName} onBlur={(event) => setcurrentName(event.target.value)} autoComplete='new-password' />
                </Form.Group>
                </Col>
            </Row>
            <Row>
              <Col md="9">
                <Form.Group>
                  <Form.Label>Items</Form.Label> {<span style={{ fontSize: 12 }}>( Drag items by # to reorder )</span>}
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="mylist">
                      {(provided) => (
                        <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                          {currentItems && currentItems.map(({ id, label, abbreviation, itemIndex }, index) => {
                            return (
                              <Draggable key={`id-${id}`} draggableId={`id-${id}`} index={index}>
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="checklist-item">                                          
                                    <InputGroup size="sm">
                                      <InputGroup.Prepend>
                                        <InputGroup.Text>
                                          {index + 1}.
                                        </InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <Form.Control style={{ height: 40, maxWidth: 60 }} type="text" id={id + "-abbreviation"} name={id} defaultValue={abbreviation} onKeyPress={(event) => saveElement('abbreviation',event)} onBlur={(event) => updateElement(id, 'abbrevation', event.target.value)}  autoComplete='new-password'/> 
                                      <Form.Control style={{ height: 40 }} type="text" id={id + "-label"} name={id} defaultValue={label} onKeyPress={(event) => saveElement('label',event)} onBlur={(event) => updateElement(id, 'label', event.target.value)}  autoComplete='new-password'/> 
                                      <InputGroup.Append>
                                        <InputGroup.Text onClick={() => deleteLabel(index)} style={{ cursor: 'pointer' }}>
                                          <DeleteOutlineIcon />
                                        </InputGroup.Text>
                                      </InputGroup.Append>
                                    </InputGroup>                                
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Add Item:</Form.Label>              
                  <div className="checklist-item">                                          
                    <InputGroup size="sm">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          &nbsp; &nbsp;
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <AddItem />
                    </InputGroup>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>                      
        </Modal.Body>        
        <Modal.Footer className="edit-md-modal">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={putSiteItemTemplate}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  )  
}

