import { put, takeLatest, all } from "redux-saga/effects";
import axios from "axios";
import { persistReducer } from "redux-persist";
// import { REHYDRATE } from 'redux-persist/lib/constants';
import storage from "redux-persist/lib/storage";
import { toast } from "react-toastify";

export const BASE_URL = process.env.REACT_APP_APIIP+"/site";

// Action Types
export const actionTypes = {

  FETCH_SITES: "FETCH_SITES",
  FETCH_SITEPLAN: "FETCH_SITEPLAN",
  FETCH_SITEQRCODE: "FETCH_SITEQRCODE",

  SET_SITES: "SET_SITES",
  SET_SITEPLAN: "SET_SITEPLAN",
  SET_SITEPLAN_STATUS: "SET_SITEPLAN_STATUS",
  SET_SITEPLAN_UPLOAD_STATUS: "SET_SITEPLAN_UPLOAD_STATUS",
  SET_SITEPLANFINAL_UPLOAD_STATUS: "SET_SITEPLANFINAL_UPLOAD_STATUS",
  SET_SITEQRCODE: "SET_SITEQRCODE",

  PUT_SITE: "PUT_SITE",
  PUT_SITEFROMTEMPLATE: "PUT_SITEFROMTEMPLATE",
  DELETE_SITE: "DELETE_SITE",
  COPY_SITE: "COPY_SITE",
  SET_FILTERS: "SET_FILTERS",

  SET_DISPLAYSITE: "SET_DISPLAYSITE",
  CLEAR_DISPLAYSITE: "CLEAR_DISPLAYSITE",
  CLEAR_SITEPLAN: "CLEAR_SITEPLAN",
};

// Initial state for this slice of the store
const initialState = {
  Filters: { sitesTerm: '', sitesFlags: 'Active', siteItemTemplatesTerm:'', sitesItemTemplateFlags: 'Active' },
  Sites: [],
  Items: [],
  SitePlan: { id: 0, sitePlanFileStream: '', sitePlanFileStreamGenerated: '', sitePlanFileStreamFinal: ''},
  SitePlanStatus: '',
  SitePlanUploadStatus: '',
  SitePlanFinalUploadStatus: '',
  SiteQRCode: null,
  DisplaySite: null,
};

// Reducer with action payloads
export const reducer = persistReducer(
  { storage, key: "SiteForce", whitelist: []},
  (state = initialState, action) => {
    switch (action.type) {
      case 'SET_SITES': {
        return {
          ...state,
          Sites : action.payload, 
        };
      }
      case 'SET_SITEPLAN': {
        return {
          ...state,
          SitePlan : action.payload, 
        };
      }
      case 'SET_SITEPLAN_STATUS': {
        return {
          ...state,
          SitePlanStatus : action.payload, 
        };
      }
      case 'SET_SITEPLAN_UPLOAD_STATUS': {
        return {
          ...state,
          SitePlanUploadStatus : action.payload, 
        };
      }
      case 'SET_SITEPLANFINAL_UPLOAD_STATUS': {
        return {
          ...state,
          SitePlanFinalUploadStatus : action.payload, 
        };
      }
      case 'SET_SITEQRCODE': {
        return {
          ...state,
          SiteQRCode : action.payload.siteQRCode, 
        };
      }
      case 'SET_FILTERS': {
        return {
          ...state, Filters: {...state.Filters,
            [action.payload.filterName]: action.payload.filterValue, 
          }
        };
      }
      case 'SET_DISPLAYSITE': {
        return {
          ...state, 
          DisplaySite : action.payload.id, 
        };
      }
      case 'CLEAR_DISPLAYSITE': {
        return {
          ...state, 
          DisplaySite : null, 
        };
      }
      case 'CLEAR_SITEPLAN': {
        return {
          ...state, 
          SitePlan: { id: 0, sitePlanFileStream: '', sitePlanFileStreamGenerated: '', sitePlanFileStreamFinal: ''},
        };
      }
      default:
          return state;
      }
  }
);

// Get Calls 

export function* fetchSites(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/sites";
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_SITES', payload: response.data});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchSitePlan(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/siteplan/" + action.payload;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_SITEPLAN', payload: response.data[0]});
      yield put({ type: 'SET_SITEPLAN_STATUS', payload: '' });
      yield put({ type: 'SET_SITEPLAN_UPLOAD_STATUS', payload: '' });
      yield put({ type: 'SET_SITEPLANFINAL_UPLOAD_STATUS', payload: '' });
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchSiteQRCode(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/siteqrcode/" + action.payload;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_SITEQRCODE', payload: response.data[0]});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

// Put/Post Calls 

export function* putSiteDetail(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    process.env.REACT_APP_DEBUG && console.log(...action.payload.formData);
    const newURL = BASE_URL + "/sitedata"
    const response = yield axios.put(newURL,action.payload.formData);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_SITES'});
      yield put({ type: 'FETCH_SITEPLAN', payload: action.payload.siteID });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putSiteFromTemplate(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/sitefromtemplate/" + action.payload.id;
    const response = yield axios.put(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Copied",{ autoClose:5000 });
      yield put({ type: 'FETCH_SITES'});
      yield put({ type: 'SET_DISPLAYSITE', payload: {id : response.data.newID}});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* copySite(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/sitecopy/" + action.payload.id;
    const response = yield axios.put(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Copied",{ autoClose:5000 });
      yield put({ type: 'FETCH_SITES'});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* deleteSite(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/site/" + action.payload.type + "/" + action.payload.id;
    const response = yield axios.delete(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok deleted');
      if (action.payload.type === 'undelete')
      {
        toast.success("UnDeleted",{ autoClose:5000 });
      }
      else
      {
        toast.success("Deleted",{ autoClose:5000 });
      }
      yield put({ type: 'FETCH_SITES'});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Delete Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Delete Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

// ************** Action Creators, return action objects with payloads ************** 
export const actions = {

  fetchSites:                          () => ({ type: actionTypes.FETCH_SITES }),
  fetchSitePlan:                (payload) => ({ type: actionTypes.FETCH_SITEPLAN, payload : payload }),
  fetchQRCode:                  (payload) => ({ type: actionTypes.FETCH_SITEQRCODE, payload : payload }),

  createSiteFromTemplate:       (payload) => ({ type: actionTypes.PUT_SITEFROMTEMPLATE, payload : payload }),
  clearDisplaySite:                    () => ({ type: actionTypes.CLEAR_DISPLAYSITE }),
  putSiteDetail:                (payload) => ({ type: actionTypes.PUT_SITE, payload : payload }),
  deleteSite:                   (payload) => ({ type: actionTypes.DELETE_SITE, payload : payload }),
  copySite:                     (payload) => ({ type: actionTypes.COPY_SITE, payload : payload }),
  setFilters:                   (payload) => ({ type: actionTypes.SET_FILTERS, payload: payload }),
  clearSitePlan:                       () => ({ type: actionTypes.CLEAR_SITEPLAN }),
};


// ************** Action Watchers ************** 
function* actionWatcher() {
  yield takeLatest('FETCH_SITES',fetchSites);
  yield takeLatest('FETCH_SITEPLAN',fetchSitePlan);
  yield takeLatest('FETCH_SITEQRCODE',fetchSiteQRCode);

  yield takeLatest('PUT_SITEFROMTEMPLATE',putSiteFromTemplate);
  yield takeLatest('PUT_SITE',putSiteDetail);
  
  yield takeLatest('DELETE_SITE',deleteSite);
  yield takeLatest('COPY_SITE',copySite);
}
  
//  ************** Saga ************** 
export function* saga() {
    yield all([
        actionWatcher()
      ]); 
}
