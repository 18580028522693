
export default function MenuConfig(roleDesc) {

    switch(roleDesc) {
      case 'Basic': 
      const itemsBasic = { 
        header: {
          self: {},
          items: []
        },
        aside: {
          self: {},
          items: [
            {
              title: "Site Plans",
              root: true,
              icon: "flaticon2-gear",
              page: "sites"
            },
          ]
        }
      };
      return itemsBasic;

    case 'Administrator': 
    const itemsAdmin = {
        header: {
          self: {},
          items: []
        },
        aside: {
          self: {},
          items: [
            {
              title: "Site Plans",
              root: true,
              icon: "flaticon2-gear",
              page: "sites"
            },
            { section: "System Configuration" },
            {
              title: "Security",
              root: true,
              bullet: "dot",
              icon: "flaticon2-digital-marketing",
              submenu: [
                {
                  title: "User Permissions",
                  page: "userroles"
                },
              ]
            }, 
            {
              title: "Site Templates",
              root: true,
              bullet: "dot",
              icon: "flaticon2-digital-marketing",
              page: "siteitemtemplates"
            },
          ]
        }
      };
      return itemsAdmin;


      case 'Public': 
      const itemsPublic = {
        header: {
          self: {},
          items: []
        },
        aside: {
          self: {},
          items: []
        }
      };
      return itemsPublic;
      default:
    }
  }



