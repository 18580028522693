import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button } from "react-bootstrap";
import { DataGrid } from '@material-ui/data-grid';
import * as Site from "../store/ducks/site.duck";
import * as Reference from "../store/ducks/reference.duck";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../_metronic/_assets/css/bootstrap/bootstrap.min.css'
import '../../_metronic/_assets/css/app.css';
import '../../_metronic/_assets/css/app2.css';

export default function SiteItemTemplateList(props) {
    
  const dispatch = useDispatch()
  const siteitemtemplates = useSelector(state => state.reference.siteitemtemplates)
  useEffect( () => { dispatch(Reference.actions.fetchSiteItemTemplates()) }, [dispatch] )

  function createSiteFromTemplate(id) 
  {
    // Ok change status of a site item template to from deleted to active
    dispatch(Site.actions.createSiteFromTemplate({ id: id}))
    props.handleClose();
  }

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'name', headerName: 'Name', width: 225},
    { field: 'none', headerName: 'Actions', width: 200,
    renderCell: (params) => (
        <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#eee", marginRight: 10 }} onClick={() => createSiteFromTemplate(params.row.id)}>
           Copy From Template 
        </Button>
    ),  },
  ];

  return (
    <>
      <div style={{ height: 400, background: "#fff" }}>
      <DataGrid rows={siteitemtemplates.filter(item => item.status === 'Active')} columns={columns} pageSize={10} checkboxSelection={false} sortModel={[{ field: 'name', sort: 'desc' }]} />
      </div>
      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  )  
}

