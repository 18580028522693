
import  authProvider  from "../../app/pages/auth/authProvider";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      // Need to handle if we are using the accesstoken for graph API use or authtoken for SiteForce API 
      if (config.url.search('graph.microsoft.com') !== -1)
      {
        // console.log('Access');
        // console.log(config.url);
        const {
          auth: { accessToken }
        } = store.getState();
        if (accessToken) {
          config.headers = {  Authorization: `Bearer ${accessToken}`, 'Content-Type' : 'application/json'}
        }
      }
      else
      {
        // console.log('Auth');
        // console.log(config.url);
        const {
          auth: { authToken }
        } = store.getState();
        if (authToken) {
          config.headers = {  Authorization: `Bearer ${authToken}`, 'Content-Type' : 'application/json'}
        }
      }
      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use((response) => {
    return response
     }, 
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        // Ok API call has failed as it is no longer logged in 
        console.log('Ok we have a problem - we have been logged out');
        originalRequest._retry = true;
        await authProvider.getIdToken()
        console.log('Re-running: ' + originalRequest);
        return axios(originalRequest);
      } 
      else
      {
        return Promise.reject(error);
      }
   });
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

export const states = [
  { "id":"", "name":""},  
  { "id":"AA", "name":"Armed Forces the Americas"},
  { "id":"AE", "name":"Armed Forces Europe"},
  { "id":"AP", "name":"Armed Forces Pacific"},
  // { "id":"", "name":""},
  { "id":"AL", "name":"Alabama"},
  { "id":"AK", "name":"Alaska"},
  { "id":"AZ", "name":"Arizona"},
  { "id":"AR", "name":"Arkansas"},
  { "id":"CA", "name":"California"},
  { "id":"CO", "name":"Colorado"},
  { "id":"CT", "name":"Connecticut"},
  { "id":"DE", "name":"Delaware"},
  { "id":"DC", "name":"District of Columbia"},
  { "id":"FL", "name":"Florida"},
  { "id":"GA", "name":"Georgia"},
  { "id":"HI", "name":"Hawaii"},
  { "id":"ID", "name":"Idaho"},
  { "id":"IL", "name":"Illinois"},
  { "id":"IN", "name":"Indiana"},
  { "id":"IA", "name":"Iowa"},
  { "id":"KS", "name":"Kansas"},
  { "id":"KY", "name":"Kentucky"},
  { "id":"LA", "name":"Louisiana"},
  { "id":"ME", "name":"Maine"},
  { "id":"MD", "name":"Maryland"},
  { "id":"MA", "name":"Massachusetts"},
  { "id":"MI", "name":"Michigan"},
  { "id":"MN", "name":"Minnesota"},
  { "id":"MS", "name":"Mississippi"},
  { "id":"MO", "name":"Missouri"},
  { "id":"MT", "name":"Montana"},
  { "id":"NE", "name":"Nebraska"},
  { "id":"NV", "name":"Nevada"},
  { "id":"NH", "name":"New Hampshire"},
  { "id":"NJ", "name":"New Jersey"},
  { "id":"NM", "name":"New Mexico"},
  { "id":"NY", "name":"New York"},
  { "id":"NC", "name":"North Carolina"},
  { "id":"ND", "name":"North Dakota"},
  { "id":"OH", "name":"Ohio"},
  { "id":"OK", "name":"Oklahoma"},
  { "id":"OR", "name":"Oregon"},
  { "id":"PA", "name":"Pennsylvania"},
  { "id":"RI", "name":"Rhode Island"},
  { "id":"SC", "name":"South Carolina"},
  { "id":"SD", "name":"South Dakota"},
  { "id":"TN", "name":"Tennessee"},
  { "id":"TX", "name":"Texas"},
  { "id":"UT", "name":"Utah"},
  { "id":"VT", "name":"Vermont"},
  { "id":"VA", "name":"Virginia"},
  { "id":"WA", "name":"Washington"},
  { "id":"WV", "name":"West Virginia"},
  { "id":"WI", "name":"Wisconsin"},
  { "id":"WY", "name":"Wyoming"},
  // { "id":"", "name":""},
  { "id":"AS", "name":"American Samoa"},
  { "id":"GU", "name":"Guam"},
  { "id":"MH", "name":"Marshall Islands"},
  { "id":"MP", "name":"Northern Mariana Islands"},
  { "id":"PR", "name":"Puerto Rico"},
  { "id":"VI", "name":"Virgin Islands, U.S."},
  // { "id":"", "name":""},
  { "id":"AB", "name":"Albert"},
  { "id":"BC", "name":"British Columbia"},
  { "id":"MB", "name":"Manitoba"},
  { "id":"NB", "name":"New Brunswick"},
  { "id":"NF", "name":"Newfoundland"},
  { "id":"NT", "name":"Northwest Territories"},
  { "id":"NS", "name":"Nova Scotia"},
  { "id":"NU", "name":"Nunavut"},
  { "id":"ON", "name":"Ontario"},
  { "id":"PE", "name":"Prince Edward Island"},
  { "id":"QC", "name":"Quebec"},
  { "id":"SK", "name":"Saskatchewan"},
  { "id":"YT", "name":"Yukon Territory"},
];

