import React,{ useEffect, useState } from "react";
import { GcPdfViewer } from "@grapecity/gcpdfviewer";
import './SitePlanEditor.css';
import { useSelector} from 'react-redux'

export const BASE_URL = process.env.REACT_APP_APIIP+"/supportapi";

export default function SitePlanEditor(props){

  const [viewerLoaded, setViewerLoaded] = useState(false);
  const username = useSelector(state => state.auth.firstName) + " " + useSelector(state => state.auth.lastName)
  
  useEffect(function(){
      if(!viewerLoaded)
      {
        console.log('Creating viewer')
        createViewer(props);
      }
  })
  return (
      <div id="wrapper" style={{height:'100%'}}>
          <div id="viewer"></div>
      </div>
  );

  function removePdfViewer(){
      if(document.querySelector("#viewer")){
          document.querySelector("#viewer").remove();
      }

  }

//   function reloadViewer() {
//     removePdfViewer();
//     createViewer(props);
//   }

  function createViewer(props){

    setViewerLoaded(true);
    console.log('stamps')
    // console.log(props.stamps.length)
    
    GcPdfViewer.LicenseKey = "siteforce.hitt-gc.com,222974648235797#B0Y1MMHFDNzEWMKlncNBFR8gTQUdFW9EUe7Y5SzFzahlGWzczLYlzTXhzYJZWU5tkWVJXV7R6RP3UM8tSVLJ7KNVTbpVGayg5aTZESq3mRiBVdMVDMPlTbXlXYTdXQHRXYwEVZ5s6KHp4cw4UVv54b9tkaGhkVx5WTOl6Z9lUTw8WcvMFOEV7M7llUaFFS6dHe5dEdkdmZiRUSpdlWDBVRzg4KyhHMYZla0lzY5RWUQJ7dBdUNGRUaGJlRSRTZulDZzdjW8ZmVCZnWzokd8h6YHtmTGFDMoJ7cIxURzVHeHVXb6FDR8d5UDVFaQJiOiMlIsIyMyMkRwQkM5IiOigkIsYTM6UTO9IDN0IicfJye#4Xfd5nISVFONJiOiMkIsIybyBHIzYFIyV6dllmVmRGUjdkI0IiTis7W0ICZyBlIsISOxAzM4ADI8ITMwIjMwIjI0ICdyNkIsISbvNmLjdWL4RXao9SZjJ7bmVGdpNnI0IyctRkIsIyYulEIn9Wa4NWYyRnbvNEIURVSIJiOiEmTDJCLicTO7UzMygDN6QzN9IjMyIiOiQWSisnOiQkIsISP3cWS634VRJmVHNVMwNHSjhlNqljVQ96S6FkaZVHMQRzQ7tGTHFjQVFHWYl6VyEDZ9lGcQ3Ce9dEb9gDbURnSX36Uu5mcxJTYQ54arl6b4klMWpFTvlmbyUkcUhVOl3UTzJWW4F4b0J6MgNjc";

    if(!document.querySelector("#viewer")){
        var v= document.createElement("div");
        v.id="viewer";
        document.querySelector("#wrapper").appendChild(v);
    }
    const options = { 
      // renderInteractiveForms: true,
      theme: "themes/light-blue",
      userName: username,
      supportApi: {
        apiUrl: BASE_URL,
        token:   props.token,
        webSocketUrl: false
      },

      workerSrc:"./plugins/gcpdfviewer/gcpdfviewer.worker.js",
      stamp: {
			stampCategories: [
                { name: 'Items', stampImageUrls: props.stamps},
                { name: 'Site Key', stampImageUrls: [props.itemkey]} 
            ]
		},
    };

    options.userData = { filename: props.filename, siteID: props.siteID, siteGUID: props.siteGUID};
    let viewer = new GcPdfViewer("#viewer", options);
    
    viewer.beforeUnloadConfirmation = true;
    viewer.addDefaultPanels();
	const annotationsPanel = viewer.addAnnotationEditorPanel();
	// viewer.layoutMode = 1;

    viewer.toolbarLayout.viewer = {
        default: ["$zoom", "edit-stamp", "$fullscreen", "text-selection", "pan"],
        fullscreen: ["$zoom", "edit-stamp", "$fullscreen", "text-selection", "pan" ],
        mobile: ["$zoom", "edit-stamp", "$fullscreen"] // Mobile looks similar to default, but you can specify different menu items here and it will work
    };
    viewer.toolbarLayout.annotationEditor = {
        default: [ 'edit-select', '$split', 'edit-stamp', '$split', 'edit-undo', 'edit-redo'],
        mobile: ['edit-select', '$split', 'edit-stamp', '$split', 'edit-undo', 'edit-redo'],
        fullscreen: ['$fullscreen', 'edit-select', '$split', 'edit-stamp', '$split', 'edit-undo', 'edit-redo']
    };
    var toolbar = viewer.toolbar;
    var saveButtonName = "custom-save";
    toolbar.addItem(
    {
        key: saveButtonName,
        icon: { type: 'svg', content: React.createElement('svg', { xmlns: 'http://www.w3.org/2000/svg', version: '1.1', width: '24', height: '24', viewBox: '0 0 24 24', fill: '#ff0000' }, React.createElement('path', { d: 'M20.913 9.058c0.057-0.26 0.087-0.531 0.087-0.808 0-2.071-1.679-3.75-3.75-3.75-0.333 0-0.657 0.044-0.964 0.125-0.581-1.813-2.28-3.125-4.286-3.125-2.047 0-3.775 1.367-4.32 3.238-0.533-0.155-1.097-0.238-1.68-0.238-3.314 0-6 2.686-6 6s2.686 6 6 6h3v4.5h6v-4.5h5.25c2.071 0 3.75-1.679 3.75-3.75 0-1.845-1.333-3.379-3.087-3.692zM13.5 15v4.5h-3v-4.5h-3.75l5.25-5.25 5.25 5.25h-3.75z' })) }, 
        // iconCssClass: 'mdi mdi-close-circle custom-close-icon',
        title: 'Save Document',
        text: 'Save',
        enabled: function () {
            return { enabled: viewer.hasDocument};
        },
        action: function () {
            console.log('Saving');
            // viewer.saveChanges();
            viewer.saveChanges().then(function(result) {
                if(result) {
                    // Load saved document from server
                    // var docUrl = window.top.SUPPORTAPI_URL + "/GetPdfFromCloud?docName=" 
                    //                + options.userData.docName + '&clientId=' + viewer.supportApi.clientId;
                    // viewer.open(docUrl).then(function() {
                    alert('Save Successful');
                    // }
                    // );
                }
            });
        }
    })
    addButtonToLayout(viewer, saveButtonName);

    var closeButtonName = "custom-close-button";
    toolbar.addItem({
        key: closeButtonName,
        iconCssClass: 'mdi mdi-close-circle custom-close-icon',
        title: 'Close Document',
        text: 'Close',
        enabled: function () {
            return { enabled: viewer.hasDocument};
        },
        action: function () {
            viewer.undoChanges();
            removePdfViewer();
            viewer.dispose();
            props.closeEditor();
        }
    })
    addButtonToLayout(viewer, closeButtonName);


    viewer.applyToolbarLayout();

    // Open the viewer, open the annotations panel to unlock them, then close it. Thus the user can start adding annotations at will.
    viewer.open(props.url)
      .then(()=> viewer.openPanel(annotationsPanel))
      .then(()=> viewer.closePanel(annotationsPanel));

    return viewer;
  }
  
}

function addButtonToLayout(viewer,buttonName) {
    for (let k in viewer.toolbarLayout) {
        let layout = viewer.toolbarLayout[k];
        layout.default.splice(0, 0, buttonName);
        layout.fullscreen.splice(0, 0, buttonName);
        //layout.mobile.splice(0, 0, buttonName);
    }
}