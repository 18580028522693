import { put, takeLatest, all } from "redux-saga/effects";
import axios from "axios";


export const BASE_URL = process.env.REACT_APP_APIIP+"/siteportal";

export const actionTypes = {
  // Init actiontypes to build reference data 
  SET_INITIAL: "SET_INITIAL",

  FETCH_SINGLESITE: "FETCH_SINGLESITE",
  SET_SINGLESITE: "SET_SINGLESITE",
};

const initialState = {
  SingleSite: { 
    name: '',
    coverPhotoName: '',
    street: '',
    city: '',
    county: '',
    stateAbbrev: '',
    zipCode: ''
  }
};

export function reducer(state = initialState, {type, ...action }) 
{
  switch (type) 
  {
    case 'SET_SINGLESITE': {
      return {
        ...state,
        SingleSite : action.payload.data
      };
    }
    case 'SET_INITIAL': {
      return initialState;
    }
    default:
      return state;
  }
}

export function* fetchSingleSite(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/singlesite/" + action.payload;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_SINGLESITE', payload: { data: response.data[0], invitetoken: action.payload }});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export const actions = {
  fetchSingleSite:                (payload) => ({ type: actionTypes.FETCH_SINGLESITE, payload : payload }),
};

function* actionWatcher() {  
  yield takeLatest('FETCH_SINGLESITE',fetchSingleSite);
}

export function* saga() {
  yield all([
    actionWatcher(),
  ]); 
}


