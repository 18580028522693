import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import Builder from "./Builder";
// import Dashboard from "./Dashboard";
// import DocsPage from "./docs/DocsPage";
// import Basic from "./Basic";
import { LayoutSplashScreen } from "../../../_metronic";

import Sites from "./Sites";

// Reference Pages 
import UserRoles from "./reference/UserRoles";
import SiteItemTemplates from "./reference/SiteItemTemplates";


export default function HomePage() {

  // console.log('In Homepage?');
  return (
    
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {          
          /* Redirect from root URL to /sites. */
          <Redirect exact from="/" to="/sites" />
        }
        {/* <Route path="/basic" component={Basic} /> */}
        <Route path="/sites" component={Sites} />
        {/* Reference Pages */}
        <Route path="/UserRoles" component={UserRoles} />
        <Route path="/siteitemtemplates" component={SiteItemTemplates} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}


