import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import * as SitePortal from "../../store/ducks/siteportal.duck";
import { Button, Row, Col, Spinner } from "react-bootstrap";
import { Paper } from '@material-ui/core';
import { saveAs } from 'file-saver';
import GMap from "../../components/GMap.jsx";

import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import '../../../_metronic/_assets/css/app2.css';

export default function SiteDisplayPage() { 
  
  const { sitetoken } = useParams();
  const dispatch = useDispatch()
  useEffect( () => { dispatch(SitePortal.actions.fetchSingleSite(sitetoken))  }, [dispatch, sitetoken] )
  const sitedetail = useSelector(state => state.siteportal.SingleSite);
 
  var location =  
    {
      address: sitedetail.street + ',' + sitedetail.city + ',' + sitedetail.stateAbbrev + ',' + sitedetail.zipCode,
      short: sitedetail.street,
      lng: parseFloat(sitedetail.lng),
      lat: parseFloat(sitedetail.lat)
    }

  const downloadPlan = () => {
    if(sitedetail.sitePortalPlanFileName && sitedetail.sitePortalPlanFileName !== '')
    {
      const file = new Blob(
        [Buffer.from(sitedetail.sitePortalPlanFileStream, 'base64')], 
        {type: 'application/pdf'});
      saveAs(file, sitedetail.sitePortalPlanFileName);
    }
  }

  if(!sitedetail.id){
    return (
      <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 10 }} >
        <Row>
          <Col md="12">
            <img src="/media/logos/logo.png" style={{ maxHeight: 60, marginBottom: 16 }} alt="HITT Logo" />
          </Col>
        </Row>
        <br />
        <Spinner animation="border" style={{ marginRight: 18 }} variant="primary" />
        <span style={{ fontWeight: 400 }}>Loading Site Plan...</span>
      </Paper>
      )
  }

  return(
    <>
    <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 10 }} >
      <Row>
        <Col md="12">
          <img src="/media/logos/logo.png" style={{ maxHeight: 60, marginBottom: 16 }} alt="HITT Logo" />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="site-data-container">
            <span className="site-label">Welcome to:</span>
            <br />
            <span className="site-data-lg">{sitedetail.name}{' ('+sitedetail.jobnumber + ')'}</span>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="site-data-container">
            {sitedetail && sitedetail.coverPhotoName ? 
              <img src={sitedetail.coverPhotoName} className="site-coverPhoto" alt="Site" />
            : null 
            }
        </Col>
      </Row>
      <Row>
        <Col md="12" className="site-data-container">
            <span className="site-label">Location:</span>
            <br />
            <span className="site-data-lg">{sitedetail.street} {sitedetail && sitedetail.city} {sitedetail && sitedetail.county} {sitedetail && sitedetail.stateAbbrev} {sitedetail && sitedetail.zipCode}</span>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="site-data-container">
            <span className="site-label">HITT Point of Contact:</span>
            <br />
            <span className="site-data-lg">{sitedetail.pointofcontact}(<a href={'tel:'+sitedetail.pointofcontactphone}>{sitedetail.pointofcontactphone}</a>)</span>
        </Col>
      </Row>
      <Row>
        <Col md="3" sm="12" style={{ marginBottom: 20, textAlign: "center" }}>
          <Button variant="primary" size="lg" onClick={downloadPlan}>
            Click for Site Logistics
          </Button> 
        </Col>
      </Row>
      <Row>
        <Col md="12" className="site-data-container">
        {sitedetail && sitedetail.lat ?
          <GMap location={location} zoomLevel={17} />
        : null
        } 
        </Col>
      </Row>
    </Paper>
    </>
  )
}

