import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest, put, all, select } from "redux-saga/effects";
// import { authProvider } from "./../../pages/auth/authProvider";
import axios from "axios";

// export const bcGroup_URL = "https://graph.microsoft.com/v1.0/groups/" + process.env.REACT_APP_AZUREBIDCALCGROUPID;
export const BASE_URL = process.env.REACT_APP_APIIP +"/user";

const assumedUserID = (state) => state.auth.assumedUserID;
const assumedUserRole = (state) => state.auth.assumedUserRole;

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Reauth: "[Reauth] Action",
  AAD_INITIALIZING: "AAD_INITIALIZING",
  AAD_LOGIN_SUCCESS: "AAD_LOGIN_SUCCESS",
  STOP_REHYDRATE: "STOP_REHYDRATE",
  SET_ADACCESS: "SET_ADACCESS",
  SET_USERROLE: "SET_USERROLE",
  AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS: "AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS",
  SET_VALIDUSER: "SET_VALIDUSER",
  AAD_ACQUIRED_ID_TOKEN_SUCCESS : "AAD_ACQUIRED_ID_TOKEN_SUCCESS",
  SET_ASSUMEDUSER: "SET_ASSUMEDUSER",
  SET_FILTERS: "SET_FILTERS",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  userName: undefined,
  firstName : undefined,
  lastName : undefined,
  // adValid: undefined,
  roleID: undefined,
  roleDesc: undefined,
  assumedUserID: 0,
  assumedUserName: '',
  assumedUserRole: '',
  Filters: { 
    csicodesVersionID: '', 
    csicodesTerm: '',
    csiCodeNo: '',
    csiCode: '', 
    tagsTerm: '', 
    usersTerm: '', 
    subcontractors: [], 
    bidtemplates: [],
    bidTradeID: 0,
    clarificationSection: {},
    bidsInProgress: { constructionDate: 'current', keyword: '' }
  }
};

// Group look up feature no longer in use 
// export function* getAccessToken()
// {
  // console.log('Get AccessToken');
//  yield authProvider.getAccessToken();
//}

//export function* checkADGroup()
//{
  // this process checks to see if the newly logged in user has an AD group of Bid Calc User
  // Uses the Group GUID in settings file 
  // console.log('Check AD Group');
//try {
//  const response = yield axios.get(bcGroup_URL);
    // Check response
//  if (response.status === 200) {
//    const retData = response;
//      if (retData.returnStatus === 'Error')  {
        // Report error
        // console.log('error : Warning Cannot Determine Group Validity (Code - ' & response.statusText);
//        yield put({ type: 'SET_ADACCESS', payload : 'OUT' });
//      } 
//      else 
//      {
        // At this stage I am assuming a successful call means the group exists for this user 
//        yield put({ type: 'SET_ADACCESS', payload : 'IN' });
//        yield put({ type: 'SET_VALIDUSER' });
//      }
//    } else {
//      console.log('error : Cannot Determine Group Validity (Code - ' & response.statusText );
//      yield put({ type: 'SET_ADACCESS', payload : 'OUT' });
//    }
//  }
//  catch (error) 
//  {
//    console.log('error: Warning Cannot Determine Group Validity (Code - SVR)');
//    yield put({ type: 'SET_ADACCESS', payload : 'OUT' });
//  }
//}

export function* getUserRole()
{
  // this process requests the Users Role which is then used to determine the appropriate menu options etc. 
  // console.log('Get User Role');
  try {
    let thisAssumedUserID = yield select(assumedUserID);
    let thisAssumedUserRole = yield select(assumedUserRole);
    const newURL = BASE_URL + "/userrole";
    const response = yield axios.get(newURL);
    // Check response
    if (response.status === 200) {
      const retData = response;
      if (retData.returnStatus === 'Error')  {
        // Report error
        // console.log('error : Warning Cannot Determine User Role  (Code - ' & response.statusText);
        yield put({ type: 'SET_USERROLE', payload : { roleID:0, roleDesc: 'Unknown' }});
      } 
      else 
      {
        // console.log(response);
        yield put({ type: 'SET_USERROLE', payload : response.data[0] });
        yield put({ type: 'SET_VALIDUSER' });
        // if impersonating a user then set the menu for their role
        if(thisAssumedUserID !== 0){
          yield put({ type: 'builder/SET_MENU_CONFIG', payload: thisAssumedUserRole })
        } else {
          yield put({ type: 'builder/SET_MENU_CONFIG', payload: response.data[0].roleDesc })
        }
      }
    } else {
      console.log('error : Cannot Determine User Role (Code - ' & response.statusText );
      yield put({ type: 'SET_USERROLE', payload : { roleID:0, roleDesc:'Unknown' }});
    }
  }
  catch (error) 
  {
    console.log('error: Warning Cannot Determine User Role (Code - SVR)');
    console.log(error)
    yield put({ type: 'SET_USERROLE', payload : { roleID:0, roleDesc:'Unknown' }});
  }
}

export function* setAssumedUser(action)
{
  // Set UserRole 
  if(action.payload.assumedUserID === 0) {
    yield put({ type: 'RESET_USERROLE' })
  } else {
    yield put({ type: 'builder/SET_MENU_CONFIG', payload: action.payload.roleDesc })
  }
}

export const reducer = persistReducer(
    { storage, key: "SiteForce-auth", whitelist: ["user", "firstName", "lastName", "roleId", "roleDesc", "assumedUserID", "assumedUserName", "assumedUserRole", "Filters"] },
    (state = initialAuthState, action) => {
      switch (action.type) {

        case actionTypes.AAD_LOGIN_SUCCESS: {
          // console.log ('In Login reducer')
          // console.log (action.payload);
          const { userName} = action.payload.account;
          const { jwtIdToken } = action.payload;
          const authToken = jwtIdToken;           
          return { ...state, authToken, userName, user: undefined };
        }

        case actionTypes.SET_USERROLE: {
          return { ...state, firstName: action.payload.firstname, lastName: action.payload.surname, roleDesc: action.payload.roleDesc }
        }

        case actionTypes.SET_ASSUMEDUSER: {
          return { ...state, assumedUserID: action.payload.assumedUserID, assumedUserName: action.payload.userName, assumedUserRole: action.payload.roleDesc }
        }

        // case actionTypes.AAD_INITIALIZING: {
        //   // console.log("Intializing AAD");
        //   break
        // }

        case actionTypes.AAD_INITIALIZED: {
          console.log("Logged in - Ready to Go");
          break 
        }

        case actionTypes.SET_ADACCESS: {
          // console.log('Setting Groups in Reducer');
          // console.log('Action Payload ' + action.payload);
          return { ...state, adValid : action.payload }
        }

        case actionTypes.AAD_ACQUIRED_ID_TOKEN_SUCCESS: {
          // console.log(action.payload.idToken.rawIdToken);
          const { rawIdToken } = action.payload.idToken;
          // console.log(rawIdToken);
          const authToken = rawIdToken;   
          if (process.env.REACT_APP_DEBUG) {
            console.log('Re Logged in Again');
          }
          return { ...state, authToken };
        }

        case actionTypes.AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS: {
          // console.log('Stored Access Token');
          // console.log(action.payload);
          const { accessToken } = action.payload;
          // const accesToken = jwtIdToken; 
          return { ...state, accessToken };
        }

        case 'SET_FILTERS': {
          return {
            ...state, Filters: {...state.Filters,
              [action.payload.filterName]: action.payload.filterValue, 
            }
          };
        }

        default:
          return state;
      }
    }
);

export const actions = {
  login: (authToken,refreshToken,firstName,lastName,roleID,roleDesc) => ({ type: actionTypes.Login, payload: { authToken, refreshToken, firstName, lastName, roleID, roleDesc } }),
  setAssumedUser: (payload) => ({ type: actionTypes.SET_ASSUMEDUSER, payload: payload }),
  setFilters:        (payload) => ({ type: actionTypes.SET_FILTERS, payload: payload }),
};

function* actionWatcher() 
{
  // yield takeLatest('AAD_LOGIN_SUCCESS',getAccessToken); 
  // yield takeLatest('AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS',checkADGroup); 

  yield takeLatest('AAD_LOGIN_SUCCESS',getUserRole);
  yield takeLatest('SET_ASSUMEDUSER',setAssumedUser);
  yield takeLatest('RESET_USERROLE',getUserRole);
}

export function* saga() {
  yield all([
    actionWatcher(),
  ]); 

}
