import React from "react";
import { Link } from "react-router-dom"; // , Switch, Route, Redirect
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";

import store from "../../store/store";
// import * as Reference from "../../store/ducks/reference.duck";
import { AzureAD, AuthenticationState, LoginType } from 'react-aad-msal';
import authProvider from "./authProvider";
// import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';


export default function AuthPage() {
  const options = authProvider.getProviderOptions();
  options.loginType = LoginType.Popup;
  authProvider.setProviderOptions(options);
  // console.log("authPage");
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/HomePage_Leadership_Banner2.png")})`
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-4.png")}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">HITT SiteForce</h3>
                  <h4 className="kt-login__subtitle">
                    Welcome to the HITT SiteForce .
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; 2021 HITT Contracting inc.
                  </div>
                  <div className="kt-login__menu">
                    {/* <Link to="/terms" className="kt-link">
                      Privacy
                    </Link>
                    <Link to="/terms" className="kt-link">
                      Legal
                    </Link>
                    <Link to="/terms" className="kt-link">
                      Contact
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <AzureAD provider={authProvider} reduxStore={store}>
                {({ login, logout, authenticationState }) => {
                    const isInProgress = authenticationState === AuthenticationState.InProgress;
                    // const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
                    const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;
                    if (isUnauthenticated || isInProgress) 
                    {
                      return(
                        <React.Fragment>
                          <div className="kt-login__body">
                              <div className="kt-login__form" style={{maxWidth: '470px'}}>
                                  <div className="kt-login__title">
                                      <h3>
                                          Log in to SiteForce
                                      </h3>
                                  </div>
                                  {/* <div role="alert" class="alert alert-danger">
                                    <div class="alert-text">
                                      <ReportProblemOutlinedIcon style={{ marginBottom: '3px', marginRight: '5px;'}} /> Confidential Business Information - Authorized access only.
                                    </div>
                                  </div> */}
                                  <div className="kt-login__title">
                                    <button className="btn btn-primary btn-elevate kt-login__btn-primary" onClick={login} style={{padding: '6px 10px 3px 8px'}} >
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30" height="30" width="30" style={{marginTop: '2px'}}>
                                      <g  transform="scale(0.27)">
                                        <path style={{fill: "#00adef", marginRight: 6}} d="m0,12.402,35.687-4.8602,0.0156,34.423-35.67,0.20313zm35.67,33.529,0.0277,34.453-35.67-4.9041-0.002-29.78zm4.3261-39.025,47.318-6.906,0,41.527-47.318,0.37565zm47.329,39.349-0.0111,41.34-47.318-6.6784-0.0663-34.739z"/>
                                      </g>
                                    </svg>  Sign in with Azure AD 
                                    </button>
                                  </div>
                              </div>
                          </div>
                        </React.Fragment>
                      );
                    }
                    else {
                      return(null);
                    }
                }}
              </AzureAD>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
