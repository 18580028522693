import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import PlacesAutoComplete from "../../components/AddressAutoComplete";
import SitePlanEditor from "../../components/SitePlanEditor";
import { Button, Modal, Form, Row, Col, Tabs, Tab, InputGroup, Spinner } from "react-bootstrap";
import { Paper, TextField, Grid, Select, MenuItem } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { DataGrid } from '@material-ui/data-grid';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import * as Site from "../../store/ducks/site.duck";
import * as Utils from '../../../_metronic/utils/utils';
import SiteItemTemplateList from "../../components/SiteItemTemplateList";
// import Files from "../../../components/Files";
import { ToastContainer, Flip } from "react-toastify";
import { saveAs } from 'file-saver';
import { format } from "date-fns";
import "react-toastify/dist/ReactToastify.css";
import '../../../_metronic/_assets/css/bootstrap/bootstrap.min.css'
import '../../../_metronic/_assets/css/app.css';
import '../../../_metronic/_assets/css/app2.css';


export default function Sites() {
    
  const dispatch = useDispatch()
  const sites = useSelector(state => state.site.Sites)
  const sitePlan = useSelector(state => state.site.SitePlan)
  const sitePlanUploadStatus = useSelector(state => state.site.SitePlanUploadStatus)
  const sitePlanFinalUploadStatus = useSelector(state => state.site.SitePlanFinalUploadStatus)
  const filters = useSelector(state => state.site.Filters)
  const displaysite = useSelector(state => state.site.DisplaySite)
    
  useEffect(() => {
    // 
    // Some Commentary about this
    // Essentially when someone creates a new site from a site item template, the application creates the new site with the site item template then opens the site modal with that site displayed
    // To do this a Redux variable - DisplaySite is set with the id (returned from the createNewSiteFromSiteItemTemplate process - a mouthful isn't it?) 
    // This DisplaySite variable is used to display the modal then cleared in another dispatch call
    //
    if (displaysite && sites.some(site => site.id === displaysite))
    {
      loadSite(sites.find(site => site.id === displaysite));
      dispatch(Site.actions.clearDisplaySite());
    }
  });

  const searchSites = (site) => {
    if( true
        && (site.name.toLowerCase().search(filters.sitesTerm.toLowerCase()) > -1 || filters.sitesTerm.length === 0)
        && (filters.sitesFlags === 'All' || (site.status === 'Active' && filters.sitesFlags === 'Active') || (site.status === 'InActive' && filters.sitesFlags === 'InActive') || (site.status === 'Deleted' && filters.sitesFlags === 'Deleted') )
      )
    {
      return site
    }  
  }
    
  const filteredSites = sites.filter(site => searchSites(site))
  useEffect( () => { dispatch(Site.actions.fetchSites()) }, [dispatch] )

  var [show, setShow] = useState(false)
  var [editorShow,setEditorShow] = useState(false)
  var [addItemError,setAddItemError] = useState(null)
  var [updateItemError,setUpdateItemError] = useState(null)
    
  function handleClose() {
    setShow(false);
  }  
  
  function handleEditorClose() 
  {
    dispatch(Site.actions.fetchSitePlan(currentSiteID));
    dispatch(Site.actions.clearSitePlan());
    setEditorShow(false);
  }

  // general function to capture Enter key press (to align it with tab/onBlur) - this will prevent the form being submitted and run relevant update/save methods instead
  function onKeyDown(event) {
    if ((event.charCode || event.keyCode) === 13) {
      // console.log(event);
      // // new checklist item
      // if(event.target.name === 'newLabel')
      // {
      //   addLabel(event.target.value)
      // }
      event.preventDefault();
    }
  }

  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
  }

  function saveElement(field,event) {
    if(event.key === 'Enter' || event.key === undefined)
    {
      // console.log(event);
      // updateElement(event.target.name, 'label', event.target.value)
    }
  }

  function addElement(abbr,label) {
    // Setup new element to add 
    // Check abbreviation is 2 char - otherwise no save for you
    if (abbr && abbr.length !== 2)
    {
      setAddItemError('Abbrevations must be 2 characters');
    }
    else
    {
      setAddItemError(null);
      var newItem = { id: makeid(6), abbreviation: abbr, label: label}
      var tempItems = currentItems ? [ ...currentItems ] : [];
      tempItems.push(newItem);
      setcurrentItems(tempItems);
    }
  }

  function updateElement(id, field, value) {
    // Before anything is merged into anthing need to see if the value is an abbr
    if (field !== 'label')
    {
      // Check abbreviation is 2 char - otherwise no save for you
      if (value.length !== 2)
      {
        setUpdateItemError('Abbrevations must be 2 characters');
      }
      else
      {
        setUpdateItemError(null);
        // merge in new label into item in items array
        var tempItems = [ ...currentItems ]
        tempItems.forEach(function(obj) 
        {
          if (obj.id === parseInt(id)) 
          {
            if (field === 'label')
            {
              obj.label = value;  
            }
            else
            {
              obj.abbreviation = value;  
            }
          }
          setcurrentItems(tempItems);
        });
      }
    }
  }

  function deleteLabel(rowindextoDelete) {
    var tempItems = currentItems.filter((row, index) => index !== rowindextoDelete);
    setcurrentItems(tempItems);
  }

  function AddItem () {
    // Define variables
    const [newAbbreviation, setNewAbbreviation] = useState('');
    const [newLabel, setNewLabel] = useState('');
    // Handle changes 
    function addNew() {
      addElement(newAbbreviation,newLabel)
      // Reset variables 
      setNewLabel('')
      setNewAbbreviation('')
    }
    return(
      <>
        <Form.Control style={{ height: 40, maxWidth: 60 }} type="text" id="itemAbbreviation" placeholder="Abbr." name="newAbbreviation" value={newAbbreviation} onChange={(event) => setNewAbbreviation(event.target.value)} autoComplete='new-password'/> 
        <Form.Control style={{ height: 40 }} type="text" id="itemLabel" placeholder="Label" name="newLabel" value={newLabel} onChange={(event) => setNewLabel(event.target.value)} autoComplete='new-password'/>
        <Button variant="primary" onClick={addNew} style={{ height: 38, marginTop: 1 }}>
          Add
        </Button>
      </>
    )
  }

  function handleOnDragEnd(result) {
    // create a new re-ordered array from the draggable context and set it back to state
    if(result.destination) {
      const itemsCopy = Array.from(currentItems);
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
      itemsCopy.splice(result.destination.index, 0, reorderedItem);
      setcurrentItems(itemsCopy);
    }
  }

  function QRCodeDownload(props) {
    // Check to see if link is populated
    if (props.documentLink && props.documentLink.length === 0)
    {
      return(null)
    }
    //
    return (
      <Button variant="info" size="sm" href={props.documentLink} style={{ marginTop: 5, paddingTop: 6 }}>
        <DescriptionIcon fontSize="small" style={{ marginRight: 6, marginTop: -1 }} /> Download
      </Button>
    )
  }

  function OpenSitePlanEditor(props) {
    //
    setEditorShow(true)
  }

  function handleFileFieldChangeSitePlanFileName(propertyName, event) {  
    // console.log(event.currentTarget.files[0]);
    // setcurrentSitePlanFileName(event.currentTarget.files[0].name) 
    setcurrentNewSitePlanFileName(event.currentTarget.files[0]) 
  }

  function handleFileFieldChangeCoverPhotoName(propertyName, event) {  
    // console.log(event.currentTarget.files[0]);
    setcurrentCoverPhotoName(event.currentTarget.files[0].name) 
    setcurrentNewCoverPhotoName(event.currentTarget.files[0]) 
  }

  const deleteSite = (id) => {
    // Ok change status of a site to deleted 
    dispatch(Site.actions.deleteSite({ id: id, type: 'delete'}))
    setShow(false);
  }

  const undeleteSite = (id) => {
    // Ok change status of a site to from deleted to active
    dispatch(Site.actions.deleteSite({ id: id, type: 'undelete'}))
    setShow(false);
  }

  const copySite = (thisSite) => {
    // Ok copy current site - needs to be done in the API due to the images and siteplans in the blob
    dispatch(Site.actions.copySite({ id: thisSite.id}))
  }

  function putSite(saveOrClose) {
    //  
    const formData = new FormData();
    // Add fields 
    formData.append('siteID',currentSiteID);
    // formData.append('sideGUID',currentSiteGUID); -- GUID cannot be updated once set
    formData.append('sitePointOfContact',currentPointofContact);
    formData.append('sitePointOfContactPhone',currentPointofContactPhone);
    formData.append('siteName',currentName);
    formData.append('siteJobNumber',currentJobNumber);
    formData.append('siteStreet',currentStreet);
    formData.append('siteCity',currentCity);
    // formData.append('siteCounty',currentCounty);
    formData.append('siteStateAbbrev',currentStateAbbrev);
    formData.append('siteZipCode',currentZipCode);
    formData.append('siteLat',currentLat);
    formData.append('siteLong',currentLong);
    // formData.append('siteCoverPhotoName',currentCoverPhotoName); -- only new setting is returned not current image 
    formData.append('newsiteCoverPhotoName',currentNewCoverPhotoName);
    // formData.append('sitePlanFileName',currentSitePlanFileName); -- only new setting is returned not current image 
    formData.append('newsitePlanFileName',currentNewSitePlanFileName);
    // formData.append('newsitePlanFileNameFinal',currentNewSitePlanFileNameFinal);
    // formData.append('sitePlanQRCodeImageName',currentSitePlanQRCodeImageName); -- GUID (and therefore QR Code image) cannot be updated once set
    // formData.append('sitePlanQRCodeImage',currentSitePlanQRCodeImage); -- GUID (and therefore QR Code image) cannot be updated once set
    formData.append('siteStatus',currentStatus);
    // console.log(JSON.stringify(currentItems));
    formData.append('siteItems',JSON.stringify(currentItems));
    //
    process.env.REACT_APP_DEBUG && console.log(...formData);
    process.env.REACT_APP_DEBUG && console.log ('Saving data');
    // Save update 
    dispatch(Site.actions.putSiteDetail({ siteID: currentSiteID, formData: formData }));

    if(currentNewSitePlanFileName){
      dispatch({ type: Site.actionTypes.SET_SITEPLAN_UPLOAD_STATUS, payload: 'Uploading...' });
    }
    if(currentNewSitePlanFileNameFinal){
      dispatch({ type: Site.actionTypes.SET_SITEPLANFINAL_UPLOAD_STATUS, payload: 'Uploading...' });
    }

    // Having issues with the client reapplying files that have already been uploaded - thus need to assume the save wil work and clear the variables 
    setcurrentNewSitePlanFileName();
    setcurrentNewSitePlanFileNameFinal();


    if(saveOrClose === 'close'){
      setShow(false);
    }
  }



  const loadNewSite = (thisSite) => {
    setcurrentSiteID(0);
    setcurrentSiteGUID();
    // setcurrentSiteGUID();
    setcurrentName();
    setcurrentJobNumber();
    setcurrentPointofContact();
    setcurrentPointofContactPhone();
    setcurrentStreet();
    setcurrentCity();
    setcurrentCounty();
    setcurrentStateAbbrev();
    setcurrentZipCode();
    setcurrentCoverPhotoName();
    setcurrentSiteQRCodeImageName();
    setcurrentStatus('Active');
    setcurrentNewCoverPhotoName();
    setcurrentNewSitePlanFileName();
    setcurrentNewSitePlanFileNameFinal();
    setcurrentLat();
    setcurrentLong();
    setcurrentItems([]);
    setcurrentSiteQRCodeJPG();
    // Load Templates 
    // dispatch(Reference.actions.fetchSiteItemTemplates());
    // Load Form
    setShow(true);
  }

  const loadSite = (thisSite) => {

    // console.log('Loading site');
    // console.log(currentNewSitePlanFileName);
    setcurrentSiteID(thisSite.id);
    setcurrentSiteGUID(thisSite.siteGUID);
    setcurrentPointofContact(thisSite.pointofcontact || '');
    setcurrentPointofContactPhone(thisSite.pointofcontactphone || '');
    setcurrentName(thisSite.name || '');
    setcurrentJobNumber(thisSite.jobnumber || '');
    // setcurrentSiteGUID(thisSite.guid);
    setcurrentStreet(thisSite.street || '');
    setcurrentCity(thisSite.city || '');
    setcurrentCounty(thisSite.county || '');
    setcurrentStateAbbrev(thisSite.stateAbbrev || '');
    setcurrentZipCode(thisSite.zipCode || '');
    setcurrentSiteQRCodeImageName(thisSite.siteQRCodeImageName || '');
    setcurrentStatus(thisSite.status || '');
    setcurrentNewCoverPhotoName();
    setcurrentNewSitePlanFileName();
    setcurrentNewSitePlanFileNameFinal();
    setcurrentLat(thisSite.lat || '');
    setcurrentLong(thisSite.lng || '');
    setcurrentItems(thisSite.siteItems);
    const img = new Image();
    img.src = thisSite.coverPhotoName;
    setcurrentCoverPhotoName(img);
    setcurrentSiteQRCodeJPG(thisSite.siteQRCodeJPG);
    // fetch plan pdf if it exists 
    dispatch(Site.actions.fetchSitePlan(thisSite.id));

    // fetch QRCode 
    // dispatch(Site.actions.fetchQRCode(thisSite.id));
    // Load Form
    setShow(true);
  }

  var [currentSiteID, setcurrentSiteID] = useState();
  var [currentSiteGUID, setcurrentSiteGUID] = useState();
  var [currentPointofContact,setcurrentPointofContact] = useState();
  var [currentPointofContactPhone,setcurrentPointofContactPhone] = useState();
  var [currentName, setcurrentName] = useState();
  var [currentJobNumber, setcurrentJobNumber] = useState();
  var [currentStreet, setcurrentStreet] = useState();
  var [currentCity, setcurrentCity] = useState();
  var [currentCounty, setcurrentCounty] = useState();
  var [currentStateAbbrev, setcurrentStateAbbrev] = useState();
  var [currentZipCode, setcurrentZipCode] = useState();
  var [currentCoverPhotoName, setcurrentCoverPhotoName] = useState();
  var [currentSiteQRCodeImageName, setcurrentSiteQRCodeImageName] = useState();
  
  var [currentStatus, setcurrentStatus] = useState();
  var [currentNewCoverPhotoName,setcurrentNewCoverPhotoName] = useState();
  var [currentNewSitePlanFileName,setcurrentNewSitePlanFileName] = useState();
  var [currentNewSitePlanFileNameFinal,setcurrentNewSitePlanFileNameFinal] = useState();
  var [currentLat,setcurrentLat] = useState();
  var [currentLong,setcurrentLong] = useState();
  var [currentItems,setcurrentItems] = useState([]);
  var [currentSiteQRCodeJPG,setcurrentSiteQRCodeJPG] = useState();
  
  console.log(currentNewSitePlanFileName);

  const downloadSitePlan = (planFile) => {
    switch (planFile) {
      case 'original': {
        if(sitePlan.sitePlanFileName && sitePlan.sitePlanFileName !== '')
        {
          const file = new Blob(
            [Buffer.from(sitePlan.sitePlanFileStream, 'base64')], 
            {type: 'application/pdf'});
          saveAs(file, sitePlan.sitePlanFileName);
        }
        break;
      }
      case 'final': {
        if(sitePlan.sitePlanFileNameFinal && sitePlan.sitePlanFileNameFinal !== '')
        {
          const file = new Blob(
            [Buffer.from(sitePlan.sitePlanFileStreamFinal, 'base64')], 
            {type: 'application/pdf'});
          saveAs(file, sitePlan.sitePlanFileNameFinal);
        }
        break;
      }
      default: 
    }    
  }

  const [key, setKey] = React.useState('siteDetails');

  var columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'name', headerName: 'Name', width: 225,
    renderCell: (params) => (
      <strong>
        <div onClick={() => loadSite(sites.find(site => site.id === params.row.id))} className="cell-link">
          {params.value} 
        </div>
      </strong>
    ),  },
    { field: 'jobnumber', headerName: 'Job #', width: 110 },    
    { field: 'city', headerName: 'Location', width: 180, 
    renderCell: (params) => (
      <>
        {params.row.city}, {params.row.stateAbbrev} 
      </>
    ),  },
    { field: 'createdByName', headerName: 'Author', width: 160 },    
    { field: 'updatedOn', headerName: 'Updated On', width: 135,  renderCell: (params) => (format(new Date(params.row.updatedOn), "dd-MM-yyyy HH:mm")) },
  ]

  if(filters.sitesFlags === 'All')
  {
    columns = [ ...columns,  { field: 'status', headerName: 'Status', width: 100 } ];
  }

  columns = [ ...columns, 
    { field: 'none', headerName: 'Actions', width: 180,
    renderCell: (params) => (
      <><a href={`/site/${params.row.siteGUID}`} target="HITTSite">
          <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#d8f2da", marginRight: 10 }}>
              View 
          </Button>
        </a>
        <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#eee", marginRight: 10 }} onClick={() => copySite(sites.find(site => site.id === params.row.id))}>
           Copy 
        </Button>
      </>
    ),  },
  ];

  function createSiteFromTemplate(id) 
  {
    dispatch(Site.actions.createSiteFromTemplate({ id: id }))
    handleClose();
  }

  return (
    <>
      <h4>
        Site Plans
      </h4>
      <Paper> 
        <Grid container style={{ padding: 10 }}>
          <Grid item md={3}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
                <Form.Control 
                  type="text"
                  value={filters.sitesTerm}
                  onChange={(event) => dispatch(Site.actions.setFilters({ filterName: 'sitesTerm', filterValue: event.target.value }))}
                  autoComplete='OFF'
                  style={{ maxWidth: 170, height: 35, backgroundColor: filters.sitesTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                />
              <InputGroup.Append onClick={() => dispatch(Site.actions.setFilters({ filterName: 'sitesTerm', filterValue: '' }))} style={{ cursor: 'pointer'}}>
                <InputGroup.Text>
                  <ClearIcon fontSize="small" style={{ color: filters.sitesTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Grid>
          <Grid item md={6}>
            <Grid container>
              <Grid item>
                <Select
                  variant="outlined"
                  margin="dense"
                  value={filters.sitesFlags}
                  onChange={(event) => dispatch(Site.actions.setFilters({ filterName: 'sitesFlags', filterValue: event.target.value }))}
                  style={{ minWidth: 140, marginLeft: 15, backgroundColor: "#ffffff" }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="InActive">InActive</MenuItem>
                  <MenuItem value="Deleted">Deleted</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ textAlign: 'right' }} md={3}>
            <Button variant="primary" size="sm" onClick={() => loadNewSite()}>
              Add Site
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ height: 700 }}>
        <DataGrid rows={filteredSites} columns={columns} pageSize={10} checkboxSelection={false} sortModel={[{ field: 'updatedOn', sort: 'desc' }]} />
      </Paper>

      <Modal show={show} onHide={handleClose} dialogClassName="edit-md-dialog-modal">
        <Modal.Header closeButton className="edit-md-modal">
          <Modal.Title>{currentSiteID !== 0 ? `Edit Site - ${currentName}` : 'Add Site'} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-md-modal" onKeyDown={onKeyDown}>
          <Form onSubmit={putSite}>
              {currentSiteID === 0 ?
                <>
                  <Grid container direction="row" justify="space-between" style={{ marginBottom: 6 }}>
                    <Grid item style={{ paddingTop: 8 }}><h4>Templates</h4></Grid>
                    <Grid item style={{ textAlign: 'right' }} md={3}>
                      <Button variant="primary" size="sm" onClick={() => createSiteFromTemplate(0)}>
                        Add Blank Site
                      </Button>
                    </Grid>
                  </Grid>
                  <SiteItemTemplateList handleClose={handleClose} />
                </>
              :
                <Tabs
                id="EditModal"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                mountOnEnter={true}
                >
                  <Tab eventKey="siteDetails" title="Profile" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                    <Row>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>HITT Job#</Form.Label>
                          <Form.Control type="text" name="siteJobNumber" defaultValue={currentJobNumber} onBlur={(event) => setcurrentJobNumber(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group >
                          <Form.Label>Project Name</Form.Label>
                          <Form.Control type="text" name="siteName" defaultValue={currentName} onBlur={(event) => setcurrentName(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Point of Contact</Form.Label>
                          <Form.Control type="text" name="sitePointofContact" defaultValue={currentPointofContact} onBlur={(event) => setcurrentPointofContact(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Contact Phone</Form.Label>
                          <Form.Control type="text" name="sitePointofContactPhone" defaultValue={currentPointofContactPhone} onBlur={(event) => setcurrentPointofContactPhone(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group style={{width: "100%"}}>
                          <Form.Label>Address Finder (start typing to search)</Form.Label>
                          <PlacesAutoComplete 
                            currentID={0}
                            currentstreet={currentStreet}
                            setcurrentcity ={(value) => setcurrentCity(value)} 
                            setcurrentcounty ={(value) => setcurrentCounty(value)}
                            setcurrentstreet ={(value) => setcurrentStreet(value)}
                            setcurrentstateabbrev = {(value) => setcurrentStateAbbrev(value)}
                            setcurrentLatitude = {(value) => setcurrentLat(value)} 
                            setcurrentLongitude = {(value) => setcurrentLong(value)}
                            setcurrentPostCode = {(value) => setcurrentZipCode(value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group >
                          <Form.Label>Address</Form.Label>
                          <Form.Control type="text"  name="siteStreet" defaultValue={currentStreet} onBlur={(event) => setcurrentStreet(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>City</Form.Label>
                          <Form.Control type="text"  name="siteCity" defaultValue={currentCity} onBlur={(event) => setcurrentCity(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>County</Form.Label>
                          <Form.Control type="text"  name="siteCounty" defaultValue={currentCounty} onBlur={(event) => setcurrentCounty(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>                  
                    </Row>
                    <Row>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>State</Form.Label>
                          <Autocomplete
                            id="siteStateAbbrev"
                            disableClearable
                            options={Utils.states}
                            value={Utils.states.find(v => v.id === currentStateAbbrev) || ""}
                            onChange={(e, value) => {
                              if (value !== null)
                              {
                                setcurrentStateAbbrev(value.id);
                              }
                            }}
                            getOptionLabel={option => option.name ? option.name : ""}
                            renderInput={(params) => 
                            <TextField 
                            {...params} 
                              type="text"
                              variant="outlined"
                              margin="dense"
                              name="state"
                              style={{ backgroundColor: '#fff', marginTop: 0 }}
                              // helperText={touched.state ? errors.state : ""}
                              // error={touched.state && Boolean(errors.state)}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'}}
                            />}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>ZipCode</Form.Label>
                          <Form.Control type="text"  name="siteZipCode" defaultValue={currentZipCode} onBlur={(event) => setcurrentZipCode(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Latitude</Form.Label>
                          <Form.Control type="text"  name="siteLat" defaultValue={currentLat} onBlur={(event) => setcurrentLat(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Longitude</Form.Label>
                          <Form.Control type="text"  name="siteLong" defaultValue={currentLong} onBlur={(event) => setcurrentLong(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Tab>

                  <Tab eventKey="sitePlanItems" title="Items" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <Form.Label>Items</Form.Label> {<span style={{ fontSize: 12 }}>( Drag items by # to reorder )</span>}
                          <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="mylist">
                              {(provided) => (
                                <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                  {currentItems && currentItems.map(({ id, label, abbreviation, itemIndex }, index) => {
                                    return (
                                      <Draggable key={`id-${id}`} draggableId={`id-${id}`} index={index}>
                                        {(provided) => (
                                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="checklist-item">                                          
                                            <InputGroup size="sm">
                                              <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                  {index + 1}.
                                                </InputGroup.Text>
                                              </InputGroup.Prepend>
                                              <Form.Control style={{ height: 40, maxWidth: 60 }} type="text" id={id + "-abbreviation"} name={id} defaultValue={abbreviation} onKeyPress={(event) => saveElement('abbreviation',event)} onBlur={(event) => updateElement(id, 'abbrevation', event.target.value)}  autoComplete='new-password'/> 
                                              <Form.Control style={{ height: 40 }} type="text" id={id + "-label"} name={id} defaultValue={label} onKeyPress={(event) => saveElement('label',event)} onBlur={(event) => updateElement(id, 'label', event.target.value)}  autoComplete='new-password'/> 
                                              <InputGroup.Append>
                                                <InputGroup.Text onClick={() => deleteLabel(index)} style={{ cursor: 'pointer' }}>
                                                  <DeleteOutlineIcon />
                                                </InputGroup.Text>
                                              </InputGroup.Append>
                                            </InputGroup>                                
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Add Item:</Form.Label>              
                          <div className="checklist-item">                                          
                            <InputGroup size="sm">
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  &nbsp; &nbsp;
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <AddItem />
                            </InputGroup>
                            {addItemError}
                            {updateItemError}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Tab> 

                  <Tab eventKey="coverPhoto" title="Cover Photo" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <Form.Label>Current Cover Photo :</Form.Label>
                          {currentCoverPhotoName && currentCoverPhotoName.src && currentCoverPhotoName.src !== '' && !currentCoverPhotoName.src.includes('null')? 
                            <img src={currentCoverPhotoName.src} style={{ maxHeight: 400, marginBottom: 30 }} alt="Site Cover" />
                          : <><br />No image uploaded.</>
                          }
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <Form.Label>Upload New Cover Photo:</Form.Label>
                          <Form.Control type="file" accept=".jpg,.jpeg" name="CoverPhotoName" onChange={(event) => handleFileFieldChangeCoverPhotoName('CoverPhotoName', event)} className="form-control" style={{ paddingTop: 6, paddingLeft: 6 }} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Tab> 

                  <Tab eventKey="sitePlan" title="Site Plan" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                    {sitePlan.id !== currentSiteID
                    ?
                      <Row>
                        <Col md="8">
                          <Spinner animation="border" style={{ marginRight: 18 }} variant="primary" />
                            <span style={{ fontWeight: 400 }}>Loading PDF Documents...</span>
                        </Col>
                      </Row>
                    :
                    <>
                    <Row>
                      <Col md="8">
                        <Form.Group>
                          <Form.Label>Original Site Plan: </Form.Label>
                          <br />
                          {sitePlan.sitePlanFileName && sitePlan.sitePlanFileName !== '' && sitePlan.sitePlanFileStream && sitePlan.sitePlanFileStream !== ''
                             ? 
                              <Button variant="primary" size="sm" onClick={()=>downloadSitePlan('original')}>
                                {sitePlan.sitePlanFileName}
                              </Button> 
                             : 
                             <Button variant="secondary" size="sm">
                               {sitePlanUploadStatus === '' ? 'No site plan uploaded' : <><Spinner animation="border" style={{ marginRight: 6, marginTop: -4 }} size="sm" variant="primary" />Uploading...</>}
                             </Button>
                            }                                               
                          </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group>
                          <Form.Label>Upload Original Site Plan:</Form.Label>
                          <Form.Control type="file" accept=".pdf" name="sitePlanFileName" onChange={(event) => handleFileFieldChangeSitePlanFileName('sitePlanFileName', event)} className="form-control" style={{ paddingTop: 6, paddingLeft: 6 }} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <Form.Group>
                          <Form.Label>Final Site Plan: </Form.Label>
                          <br />
                          {sitePlan.sitePlanFileNameFinal && sitePlan.sitePlanFileNameFinal !== ''
                          ?
                            <Button variant="primary" size="sm" onClick={()=>downloadSitePlan('final')}>
                              {sitePlan.sitePlanFileNameFinal}
                            </Button>  
                          :
                            <Button variant="secondary" size="sm">
                              {sitePlanFinalUploadStatus === '' ? 'No final site plan uploaded' : <><Spinner animation="border" style={{ marginRight: 6, marginTop: -4 }} size="sm" variant="primary" />Uploading...</>}
                            </Button>
                          }                                              
                          </Form.Group>
                      </Col>
                      <Col md="4">
                          <Form.Label>Edit Site Plan Items: </Form.Label>
                          <br />  
                          {sitePlan.sitePlanFileNameFinal && sitePlan.sitePlanFileNameFinal !== ''
                          ?                        
                            <Button variant="success" size="sm" onClick={()=>OpenSitePlanEditor()}>
                                Edit Final Plan
                            </Button>
                          : null}
                      </Col>
                    </Row>
                    </>
                    }
                  </Tab> 
                  <Tab eventKey="qrcode" title="QR Code" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <Form.Label>Current QR Code :</Form.Label>
                            {currentSiteQRCodeJPG ? 
                              <img src={currentSiteQRCodeJPG} className="site-coverPhoto" alt="Site  QR Code" />
                            : <div style={{ marginLeft: 15, marginTop: 15 }}>QR Code Created on First Save</div>
                            } 
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Form.Group>
                          <div style={{ width: "100%", paddingTop: 25 }}>
                            {currentSiteQRCodeImageName ? 
                              <QRCodeDownload documentLink={currentSiteQRCodeImageName} />
                            : null 
                            }
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Tab> 
                </Tabs>
              }
          </Form>                      
        </Modal.Body>        
        <Modal.Footer className="edit-md-modal">
        <Grid container justify="space-between">
          <Grid item > 
            {currentSiteID !== 0 ?
              <>
                {currentStatus === 'Deleted' ?
                  <Button variant="warning" onClick={()=>undeleteSite(currentSiteID)} style={{ marginLeft: 8 }}>
                      Undo Delete
                  </Button>
                :
                  <Button variant="danger" onClick={()=>deleteSite(currentSiteID)} style={{ marginLeft: 8 }}>
                      Delete
                  </Button>
                }
              </>
              :
              null
            }
          </Grid>
          <Grid item>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={()=>putSite('save')} style={{ marginLeft: 8 }}>
              Save
            </Button>  
            <Button variant="primary" onClick={()=>putSite('close')} style={{ marginLeft: 8 }}>
              Save &amp; Close
            </Button> 
          </Grid>
        </Grid>       
        </Modal.Footer>
      </Modal>

      <Modal show={editorShow} onHide={handleEditorClose} dialogClassName="view-fullscreen-dialog-modal">
        <Modal.Header className="edit-lg-modal">
          <Modal.Title>{currentSiteID !== 0 ? `Edit Site Plan - ${currentName}` : 'Add Site'} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-lg-modal" style={{ padding: 0 }}>
          <div style={{ height: '900px' }} >
            <SitePlanEditor 
              id="my-viewer"
              url={"data:text/plain;base64," + sitePlan.sitePlanFileStreamFinal}
              itemkey={"data:image/png;base64," + sitePlan.siteItemKeyStream}
              stamps={sitePlan.siteItemImages}
              showDefaultPanel={true}
              filename={sitePlan.sitePlanFileNameFinal}
              siteID={currentSiteID}
              token={makeid(6)}
              siteGUID={currentSiteGUID}
              closeEditor={handleEditorClose}
            />
          </div>
        </Modal.Body>
      </Modal>



      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  )  
}

