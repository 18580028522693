import { all, take } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as site from "./ducks/site.duck";
import * as siteportal from "./ducks/siteportal.duck";
import * as reference from "./ducks/reference.duck";
import { metronic } from "../../_metronic";
import { REHYDRATE } from 'redux-persist/lib/constants';

// const authT = (state) => state.auth.authToken; 

export const rootReducer = combineReducers({
  auth: auth.reducer,
  site: site.reducer,
  siteportal: siteportal.reducer,
  reference: reference.reducer,
  builder: metronic.builder.reducer,
  i18n: metronic.i18n.reducer
});

export function* rootSaga() {
  if (process.env.REACT_APP_DEBUG) {
    console.log("Waiting for ALL rehydration")
  }
  yield take(REHYDRATE); // Wait for rehydrate to prevent sagas from running with empty store
  if (process.env.REACT_APP_DEBUG) {
    console.log("Rehydrated ALL")
  }
  yield all([auth.saga(),site.saga(),siteportal.saga(),reference.saga()]);
}
