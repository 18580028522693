/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
// import Basic from "../pages/home/Basic";
import ErrorsPage from "../pages/errors/ErrorsPage";

import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import SiteDisplayPage from "../pages/SitePortal/SiteDisplayPage";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation, roleDesc } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.authToken != null,
      roleDesc: auth.roleDesc === '' ? 'Basic' : auth.roleDesc,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );
  
  function roleCheck() {
    // console.log('Role Check - Role Desc: ' + roleDesc)
    if (roleDesc === 'Basic' || roleDesc === undefined) 
    {
      // console.log('RoleCheck - Basic');
      // return <Redirect from="/auth" to="/basic" />
      return <Redirect from="/auth" to="/sites" />
    } 
    else 
    {
      return <Redirect from="/auth" to={userLastLocation === '/sites' ? '/sites' : userLastLocation} />
    }
  }
  
  function roleCheckLayout(props) {
    // console.log('Role Check Layout - Role Desc: ' + roleDesc)
    if (roleDesc === 'Basic') {
      // console.log('RoleCheckLayout - Basic');
      return (
      <Layout>
        <HomePage userLastLocation={userLastLocation === '/sites' ? '/sites' : userLastLocation} />
        {/* <Basic userLastLocation="/basic" /> */}
      </Layout>
      )
    } else {
      return (
        <Layout>
          <HomePage userLastLocation={userLastLocation === '/sites' ? '/sites' : userLastLocation} />
        </Layout>
      )
    }
  }
  
  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>

        {/* Anonymous page */}
        <Route path="/site/:sitetoken" component={SiteDisplayPage} />

        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <AuthPage />
        ) : (
          /* Otherwise redirect to root page (`/`) */
            roleCheck()          
        )}

        <Route path="/error" component={ErrorsPage} />

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          roleCheckLayout()          
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
