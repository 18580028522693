import { put, takeLatest, all } from "redux-saga/effects";
import axios from "axios";
import { toast } from "react-toastify";

export const BASE_URL = process.env.REACT_APP_APIIP+"/reference";

export const actionTypes = {
  // Init actiontypes to build reference data 
  FETCH_SITEITEMTEMPLATES: "FETCH_SITEITEMTEMPLATES",
  SET_SITEITEMTEMPLATES: "SET_SITEITEMTEMPLATES",
  PUT_SITEITEMTEMPLATE: "PUT_SITEITEMTEMPLATE",

  DELETE_SITEITEMTEMPLATE: "DELETE_SITEITEMTEMPLATE",
  COPY_SITEITEMTEMPLATE: "COPY_SITEITEMTEMPLATE",

  FETCH_USERS: "FETCH_USERS",
  PUT_USER: "PUT_USER",
  DELETE_USER: "DELETE_USER",

};

const initialState = {
  siteitemtemplates: [],
  Users: []
};

export function reducer(state = initialState, {type, ...action }) 
{
  switch (type) 
  {
    case 'SET_REFERENCE': {
      return {
        ...state,
        [action.payload.referenceName]: action.payload.grid, 
      };
    }
    case 'SET_SITEITEMTEMPLATES': {
      return {
        ...state,
        siteitemtemplates : action.payload, 
      };
    }
    default:
      return state;
  }
}

// Get Calls 

export function* fetchSiteItemTemplates(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/siteitemtemplates";
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_SITEITEMTEMPLATES', payload: response.data});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}


export function* fetchUsers(action) {
  const newURL = BASE_URL + "/users";
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action));
  try 
  {
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
      yield put({ type: 'SET_REFERENCE', payload: { referenceName: 'Users', grid: response.data } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message : response.data.returnText } });
    }  
  } 
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}


// Put/Post Calls 

export function* putUser(action) {
  const newURL = BASE_URL + "/siteuser";
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action));
  try 
  {
    // const jPayload =  JSON.stringify(action.payload)
    const response = yield axios.put(newURL, action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
      yield put({ type: 'FETCH_USERS' })
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message : response.data.returnText } });
    }  
  } 
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* deleteUser(action) {
  const newURL = BASE_URL + "/deleteuser";
  process.env.REACT_APP_DEBUG && console.log(newURL + ' - ' + JSON.stringify(action));
  try 
  {
    const jPayload =  JSON.stringify(action.payload)
    const response = yield axios.put(newURL, {jPayload});
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
      yield put({ type: 'FETCH_USERS' })
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message : response.data.returnText } });
    }  
  } 
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putSiteItemTemplateDetail(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(action);
    const newURL = BASE_URL + "/siteitemtemplatedata"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_SITEITEMTEMPLATES'});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* copySiteItemTemplate(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/siteitemtemplatecopy/" + action.payload.id;
    const response = yield axios.put(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Copied",{ autoClose:5000 });
      yield put({ type: 'FETCH_SITEITEMTEMPLATES'});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* deleteSiteItemTemplate(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/siteitemtemplate/" + action.payload.type + "/" + action.payload.id;
    const response = yield axios.delete(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok deleted');
      if (action.payload.type === 'undelete')
      {
        toast.success("UnDeleted",{ autoClose:5000 });
      }
      else
      {
        toast.success("Deleted",{ autoClose:5000 });
      }
      yield put({ type: 'FETCH_SITEITEMTEMPLATES'});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Delete Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Delete Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

// ************** Action Creators, return action objects with payloads ************** 
export const actions = {
  fetchSiteItemTemplates:              () => ({ type: actionTypes.FETCH_SITEITEMTEMPLATES }),
  putSiteItemTemplate:          (payload) => ({ type: actionTypes.PUT_SITEITEMTEMPLATE, payload : payload }),
  deleteSiteItemTemplate:       (payload) => ({ type: actionTypes.DELETE_SITEITEMTEMPLATE, payload : payload }),
  copySiteItemTemplate:         (payload) => ({ type: actionTypes.COPY_SITEITEMTEMPLATE, payload : payload }),

  fetchUsers:                          () => ({ type: actionTypes.FETCH_USERS }),
  putUser:                      (payload) => ({ type: actionTypes.PUT_USER, payload : payload }),
  deleteUser:                   (payload) => ({ type: actionTypes.DELETE_USER, payload : payload }),
};


// ************** Action Watchers ************** 
function* actionWatcher() {
  yield takeLatest('FETCH_SITEITEMTEMPLATES',fetchSiteItemTemplates);
  yield takeLatest('PUT_SITEITEMTEMPLATE',putSiteItemTemplateDetail);
  yield takeLatest('DELETE_SITEITEMTEMPLATE',deleteSiteItemTemplate);
  yield takeLatest('COPY_SITEITEMTEMPLATE',copySiteItemTemplate);
  
  yield takeLatest('FETCH_USERS',fetchUsers);
  yield takeLatest('PUT_USER',putUser);
  yield takeLatest('DELETE_USER',deleteUser);
}
  
//  ************** Saga ************** 
export function* saga() {
    yield all([
        actionWatcher()
      ]); 
}

