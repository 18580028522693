import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Reference from "../../../store/ducks/reference.duck";
import * as Auth from "../../../store/ducks/auth.duck";
import { Button, Modal, Form, Row, Col, Tabs, Tab, InputGroup } from "react-bootstrap";
import { Paper, Select, MenuItem, Grid  } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { DataGrid } from '@material-ui/data-grid';
import { ToastContainer } from "react-toastify";
import { format } from "date-fns";
import "react-toastify/dist/ReactToastify.css";
import '../../../../_metronic/_assets/css/bootstrap/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

export function UserRoles(props) {

  const filters = useSelector(state => state.auth.Filters)
  const users = useSelector(state => state.reference.Users)
  const dispatch = useDispatch()
  
  useEffect( () => { dispatch(Reference.actions.fetchUsers())}, [dispatch])

  const initUser = () => {
    return { userID: 0, firstname: '', surname: '', email: '', roleDesc: 'Administrator',  status: 'Active'}
  }

  const [usersTerm, setusersTerm] = useState(filters.usersTerm);
  const [show, setShow] = useState(false)
  const [addForm, setAddForm] = useState(initUser())
  const [showDelete, setShowDelete] = useState(false) 
  
  const filteredusers = users.filter(user => (user.firstname.toLowerCase().search(usersTerm.toLowerCase()) > -1 || user.surname.toLowerCase().search(usersTerm.toLowerCase()) > -1 || usersTerm.length === 0))

  function handleClose() {
    setShow(false);
  }

  function handleTextFieldChange(propertyName, event) {  
    setAddForm({...addForm, [propertyName]: event.target.value} ) 
  }

  function putUser() {
    dispatch(Reference.actions.putUser({ user: addForm }));
    console.log('save')
    loadUser(initUser());
    setShow(false);
  }

  const deleteUser = () => {
    console.log('delete')
    setShowDelete(true)
  }

  const confirmDelete = () => {
    dispatch(Reference.actions.deleteUser({ ...addForm }));
    loadUser(initUser());
    setShowDelete(false)
    setShow(false);
  }

  const loadUser = (thisUser) => {
    // console.log(thisUser)
    setAddForm({ userID: thisUser.userID, userfirstname: thisUser.firstname, usersurname: thisUser.surname, useremail: thisUser.email, userroleName: thisUser.roleDesc, userstatus: thisUser.status});
    setShow(true);
  }

  const [key, setKey] = React.useState('userDetails');

  const columns = [
    { field: 'id', headerName: 'id', hide: true},
    { field: 'userID', headerName: 'ID', hide: true},
    { field: 'fullName', headerName: 'Name', width: 200,
    renderCell: (params) => (
      <strong>
        <div onClick={() => loadUser(users.find(user => user.id === params.row.id))} className="cell-link">
          {params.row.firstname} {params.row.surname}
        </div>
      </strong>
    ),  },
    { field: 'email', headerName: 'Email', width: 280 },
    { field: 'roleDesc', headerName: 'Role', width: 130 },
    { field: 'status', headerName: 'Status', width: 100 },    
    { field: 'createdOn', type: 'date', headerName: 'Created', width: 130, valueFormatter: ({ value }) => format(new Date(value), "dd-MM-yyyy HH:mm") }
  ];

  function changeFilter(value) {
    setusersTerm(value);
    dispatch(Auth.actions.setFilters({ filterName: 'usersTerm', filterValue: value }))
  }

  return (
    <>
    <h4>
      Users
    </h4>

    <Paper> 
        <Grid container justify="space-between" style={{ padding: 10 }}>
            <Grid item style={{ marginTop: 2, marginLeft: 2 }}> 
              <Grid container>
                <Grid item>
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control                  
                        type="text"
                        value={usersTerm}
                        onChange={(event) => changeFilter(event.target.value)}
                        autoComplete='OFF'
                        style={{ maxWidth: 170, height: 36, backgroundColor: usersTerm !== '' ? 'rgb(250 239 195)' : 'white' }}
                      />
                    <InputGroup.Append onClick={() => changeFilter('')}>
                      <InputGroup.Text>
                        <ClearIcon fontSize="small" style={{ color: usersTerm !== '' ? '#222222' : 'silver', marginLeft: -3 }} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Grid>
              </Grid>
          </Grid>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => {
                setKey('userDetails')
                loadUser(initUser())
              }}>
              Add User
            </Button>
          </Grid>
        </Grid>
      </Paper>

    <Paper style={{ height: 700 }}>
      <DataGrid rows={filteredusers} columns={columns} checkboxSelection={false} sortModel={[{ field: 'fullName', sort: 'asc' }]} rowsPerPageOptions={[10,15,20]} rowHeight={38} pageSize={15} />
    </Paper>

    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />

    <Modal show={show} onHide={handleClose} dialogClassName="edituser-modal">
      <Modal.Header closeButton className="edituser-modal">
        <Modal.Title>Edit User - {addForm.userFirstname} {addForm.userSurname}</Modal.Title>
      </Modal.Header>
      
      <Modal.Body className="edituser-modal">
        <Tabs
          id="EditUser"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="userDetails" title="User Details" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                    
            <Form onSubmit={putUser}>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" id="userfirstname" name="userfirstname" defaultValue={addForm.userfirstname} onBlur={(event) => handleTextFieldChange('userfirstname', event)} autoComplete='new-password' />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" id="usersurname" name="usersurname" defaultValue={addForm.usersurname} onBlur={(event) => handleTextFieldChange('usersurname', event)} autoComplete='new-password' />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" id="useremail" name="useremail" defaultValue={addForm.useremail} onBlur={(event) => handleTextFieldChange('useremail', event)} autoComplete='new-password' />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>User Role</Form.Label>
                    <br></br>
                    <Select
                      id="userroleName"
                      name="userroleName"
                      variant="outlined"
                      margin="dense"
                      className="select"                                    
                      style={{backgroundColor: "white"}}
                      value={addForm.userroleName}
                      onChange={(event) => handleTextFieldChange('userroleName', event)}
                      >
                      <MenuItem key='Basic' value='Basic' label='Basic'>Basic</MenuItem>
                      <MenuItem key='Administrator' value='Administrator' label='Administrator'>Administrator</MenuItem>
                    </Select>                                                            
                  </Form.Group>
                  </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Status</Form.Label>                                
                    <br></br>
                    <Select
                      id="userstatus"
                      name="userstatus"
                      variant="outlined"
                      margin="dense"
                      className="select"                                    
                      style={{backgroundColor: "white"}}
                      value={addForm.userstatus}
                      onChange={(event) => handleTextFieldChange('userstatus', event)}
                      >
                      <MenuItem key='Active' value='Active' label='Active'>Active</MenuItem>
                      <MenuItem key='Inactive' value='Inactive' label='Inactive'>Inactive</MenuItem>
                    </Select>                             
                  </Form.Group>
                </Col>
              </Row>                            
            </Form>                    
          </Tab>
      </Tabs>

      </Modal.Body>
      <Modal.Footer className="edituser-modal">
        <Grid container justify="space-between">
          <Grid item> 
            {addForm.userID !== 0 ?
              <>
                <Button variant="danger" onClick={deleteUser} style={{ marginLeft: 8 }}>
                    Delete
                </Button>
              </>
              :
              null
            }
          </Grid>
          <Grid item>
            <Button variant="secondary" onClick={handleClose}>
                Cancel
            </Button>
            <Button variant="primary" onClick={putUser} style={{ marginLeft: 8 }}>
                Save
            </Button>
          </Grid>
        </Grid>
      </Modal.Footer>
    </Modal>
    
    <Modal show={showDelete} onHide={()=>setShowDelete(false)} centered dialogClassName="delete-confirm-modal-dialog">
      <Modal.Header closeButton className="delete-confirm-modal-header">
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body className="delete-confirm-modal-body">
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          Are you sure you want to delete <span style={{ fontWeight: 700 }}>{addForm.userfirstname} {addForm.usersurname}</span>?
        </div>
        <div style={{ paddingBottom: 20 }}>
        If {addForm.userfirstname} has saved any site plan data, then they will be set to 'Deleted', otherwise they will be permanently deleted.
        </div>
      </Modal.Body>
      <Modal.Footer className="delete-confirm-modal-footer">
        <Button variant="secondary" onClick={()=>setShowDelete(false)}>
          Close
        </Button>
        <Button variant="danger" onClick={confirmDelete}>
          Confirm Delete
        </Button>
      </Modal.Footer>
    </Modal>

    </>
  )

}

export default UserRoles

