import React from "react";
// import { useSelector } from 'react-redux';


export function Footer() {

  // const status = useSelector(state => state.project.Status);
  // const refstatus = useSelector(state => state.project.RefStatus);
  // // console.log(status);
  // console.log(refstatus);
    return (
      <div>
        {/* {status}
        {refstatus} */}
      </div>
    );
  
}

export default Footer

// const mapStateToProps = store => ({
//   fluid:
//     objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
//   footerClasses: builder.selectors.getClasses(store, {
//     path: "footer",
//     toString: true
//   }),
//   footerContainerClasses: builder.selectors.getClasses(store, {
//     path: "footer_container",
//     toString: true
//   })
// });

// export default connect(mapStateToProps)(Footer);
