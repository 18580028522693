import React from "react";
import usePlacesAutocomplete, {getGeocode, getLatLng, getZipCode, getDetails} from "use-places-autocomplete";
import SearchIcon from '@material-ui/icons/Search';
import { Form, InputGroup } from "react-bootstrap";

const PlacesAutoComplete = (props) => {

  const {
    value,
    suggestions: { status, data },
    clearSuggestions,
    setValue
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions : {country: 'us'},
    },
    // defaultValue : props.currentfulladdress,
    debounce: 300,
  });

  function returnAddress(addressDetails,item)
  {
    // Receives array of address information and the type being found 
    var returnValue = '';
    // First check there is an address 
    if (addressDetails && addressDetails.address_components)
    {
        // Loop on Address Components 
        addressDetails.address_components.forEach((addressRecord) => {
            // Get type - another array
            addressRecord.types.forEach((addressType) => {
                if (addressType === item) 
                {
                    // Ok found one check if state value - only return abbreviation for state values 
                    if (item === 'administrative_area_level_1')
                    {
                        returnValue = addressRecord.short_name; 
                    }
                    else
                    {
                        // Check to see if county - for some reason the word county is added to all county entries in Google - annoying 
                        if (item === 'administrative_area_level_2')
                        {
                            returnValue = addressRecord.long_name; 
                            returnValue = returnValue.replace('County',''); 
                        }
                        else
                        {
                            returnValue = addressRecord.long_name; 
                        }
                    }
                    // console.log(item);
                    // console.log(returnValue);
                }
            })
        })
        return(returnValue);
    }
  }

  const handleSelect = ({ place_id,description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    const parameter = {
        placeId: place_id,
        fields: ['address_components'],
    };

    // And this is where civilisation ended and dark stuff commenced 
    // Getting address details to break up into the various fields 
    getDetails(parameter)
      .then((details) => {
        props.setcurrentcity(returnAddress(details,'locality'));
        props.setcurrentcounty(returnAddress(details,'administrative_area_level_2'));
        props.setcurrentstreet(returnAddress(details,'street_number') + ' ' + returnAddress(details,'route'));
        props.setcurrentstateabbrev(returnAddress(details,'administrative_area_level_1'));
        console.log("Details: ", details);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });


    // props.setcurrentfulladdress(description);
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        props.setcurrentLatitude(lat);
        props.setcurrentLongitude(lng);
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });

    // Get ZipCode via utility functions 
    getGeocode({ address: description })
    .then((results) => getZipCode(results[0],false))
    .then(( zipCode ) => {
      props.setcurrentPostCode(zipCode);
    })
    .catch((error) => {
      console.log("😱 Error: ", error);
    });

  };

  const handleInput = (e) => {
    // Place a "string" to update the value of the input element
    setValue(e.target.value);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control type="text" name="profilefulladdress" value={value} onChange={handleInput} autoComplete='new-password' isInvalid={props.isInvalid} isValid={props.isValid} />
      </InputGroup>

      {/* <Form.Control type="text" name="profilefulladdress" value={value} onChange={handleInput} autoComplete='new-password' isInvalid={props.isInvalid} isValid={props.isValid} /> */}
      {/* <input value={value} onChange={handleInput} /> */}
      {status === 'OK' && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};

export default PlacesAutoComplete