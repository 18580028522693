import React from 'react'
import GoogleMapReact from 'google-map-react'
import RoomIcon from '@material-ui/icons/Room';
import './gmap.css'


const GMap = ({ location, zoomLevel }) => (
    <div className="map">
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBQWKUA9NvwN_KklXmZvKCVHFlP0rW2YlU' }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
        >
          <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={location.short}
          />
        </GoogleMapReact>
      </div>
    </div>
  )

  const LocationPin = ({ text }) => (
    <div className="pin">
      <RoomIcon />
      <p className="pin-text">{text}</p>
    </div>
  )


  export default GMap;