import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// console.log('Using AuthProvider No DBG Version');

export const authProviderNODBG = new MsalAuthProvider(
    {
        auth: {
            authority: process.env.REACT_APP_AZUREAUTHORITY, //Directory (tenant) ID Overview blade of App Registration
            clientId: process.env.REACT_APP_AZURECLIENTID, //Application (client) ID
            // postLogoutRedirectUri: "http://localhost:3000/dashboard", 
            redirectUri: process.env.REACT_APP_AZUREREDIRECTUIRI
            // validateAuthority: true,

            // After being redirected to the "redirectUri" page, should user
            // be redirected back to the Url where their login originated from?
            // navigateToLoginRequestUrl: false                       
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: true,
        },
    },
    {
        scopes: ['https://graph.microsoft.com/.default'],
    },

    LoginType.Popup
    
);

